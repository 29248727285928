var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var VV,XV,ZV,$V,Xma,bW,cW,UV,dW,gW,Yma,Zma,$ma,ana,iW,jW,lW,mW,nW,oW,pW,qW,cna,dna,sW,ena,tW,fna,vW,gna,hna,ina,jna,kna,RV;$CLJS.SV=function(a,b){if("string"===typeof b)return RV(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.TV=function(a,b,c){var d=$CLJS.El(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Cb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
VV=function(a){var b=UV;return $CLJS.Iz(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};$CLJS.WV=function(a,b){return $CLJS.Iz(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};XV=function(a){if($CLJS.id(a))return a;throw $CLJS.Uh("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.pi,a],null));};
$CLJS.YV=function(a,b,c){return $CLJS.sd(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,X=G;G=$CLJS.Yd.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[X,G],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.Yd.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):$CLJS.rd(c)?$CLJS.gk.g($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Yd.g(b,$CLJS.z(c)):b),c):c};ZV=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.de(a):b;return $CLJS.n(b)?[$CLJS.de(a),"/",$CLJS.Xg(a)].join(""):$CLJS.Xg(a)};
$V=function(a,b){var c=$CLJS.rd(b)&&!$CLJS.uf(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
Xma=function(a,b){return $CLJS.df(function(c){var d=$CLJS.ff;a:try{if($CLJS.ud(c)&&1<=$CLJS.D(c))try{var e=$CLJS.lk.j(c,0,1);if($CLJS.ud(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Qe($CLJS.E,a)(f))){var k=$CLJS.lk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Z;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Sa,l)))},$CLJS.H([b]))};
bW=function(a,b){for(;;)switch(b=$CLJS.xk.h($CLJS.ff($CLJS.Sa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),aW.h?aW.h(a):aW.call(null,a);default:if($CLJS.n($CLJS.Me($CLJS.Qe($V,a),b))){var c=a;b=Xma(a,b);a=c}else{c=$CLJS.cf.g(aW,b);if($CLJS.E.g(c,b))return $CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};cW=function(a){var b=$CLJS.rd(a);return b?(b=!$CLJS.uf(a))?$CLJS.zz($CLJS.be,$CLJS.Wa)($CLJS.z(a)):b:b};
UV=function(a){return $CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a))?$CLJS.mh.h($CLJS.Gz(ZV(a).toLowerCase(),/_/,"-")):a};dW=function(a,b){var c=cW(b);return $CLJS.n(c)?(b=UV($CLJS.z(b)),$CLJS.pd(a)?(a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};
gW=function(a){a=eW.g?eW.g(a,fW):eW.call(null,a,fW);var b=$CLJS.n($CLJS.tA.h(a))?$CLJS.hk.j(a,$CLJS.tA,$CLJS.mh):a;b=$CLJS.n($CLJS.qF.h(a))?$CLJS.hk.j(b,$CLJS.qF,$CLJS.mh):b;return $CLJS.n($CLJS.uQ.h(a))?$CLJS.hk.j(b,$CLJS.uQ,function(c){return $CLJS.n($CLJS.CD.h(c))?$CLJS.hk.j(c,$CLJS.CD,$CLJS.mh):c}):b};
Yma=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.Oh,$CLJS.Pd,$CLJS.Si,UV,$CLJS.RM,UV],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ae([a,b]);b=eW.g?eW.g(b,fW):eW.call(null,b,fW);return d.call(c,b,a)})};
Zma=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=UV(c);var d=Yma(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.Si.h(a),$CLJS.DE)&&$CLJS.Ra($CLJS.RM.h(a))?$CLJS.R.j(a,$CLJS.RM,$CLJS.CF):a};$ma=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=ZV(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(Zma(b),$CLJS.U,c)],null)}),a)};
ana=function(a){a=eW.h?eW.h(a):eW.call(null,a);return hW.h?hW.h(a):hW.call(null,a)};iW=function(a){return $CLJS.TV($CLJS.TV($CLJS.cb(function(b,c){return $CLJS.TV(b,c,$CLJS.mh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.cC,$CLJS.vga,$CLJS.EN,bna,$CLJS.Yy,$CLJS.di],null)),$CLJS.fB,ana),$CLJS.TM,$CLJS.vU)};jW=function(a){return $CLJS.Ad(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,a,null],null):a};lW=function(a){return kW.h(jW(a))};
mW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return aW($CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.g(kW,b)))};nW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,lW(b)],null),$CLJS.cf.g(kW,c))};oW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,lW(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
pW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,lW(a)],null)};qW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,kW.h(a)],null)};cna=function(a){return $CLJS.yf($CLJS.Bl(hW,rW(a)))};dna=function(a){for(;;)if($CLJS.n(cW(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Be($CLJS.gk.g(jW,a))};
sW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.DE);return $CLJS.n(b)?$CLJS.hk.j(a,$CLJS.DE,kW):a};
ena=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,sW(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,
2,5,$CLJS.Q,[l,sW(f)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};tW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.GP);return $CLJS.n(b)?$CLJS.hk.j(a,$CLJS.GP,ena):a};fna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.jP);b=$CLJS.Ra(a)?uW.h?uW.h(b):uW.call(null,b):b;return $CLJS.n(a)?tW(b):b};vW=function(a){return $CLJS.pd(a)?$CLJS.y(a):null!=a};
gna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.GE);var c=$CLJS.J.g(b,$CLJS.vM),d=$CLJS.J.g(b,$CLJS.SN),e=$CLJS.J.g(b,$CLJS.jP);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.SN);var l=$CLJS.XK($CLJS.Xj.g(f,$CLJS.SN),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GE,$CLJS.SN],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.hk.j(l,$CLJS.GE,uW):l,t=$CLJS.n(c)?$CLJS.hk.j(m,$CLJS.vM,$CLJS.Qe($CLJS.gk,hW)):m,u=$CLJS.n(e)?$CLJS.hk.j(t,$CLJS.jP,tW):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.jP);return $CLJS.n(x)?$CLJS.R.j(hW($CLJS.Xj.g(v,
$CLJS.jP)),$CLJS.jP,x):hW(v)}catch(A){if(A instanceof Error)throw m=A,$CLJS.Th($CLJS.hD("Error canonicalizing query: {0}",$CLJS.H([$CLJS.tU(m)])),new $CLJS.h(null,1,[$CLJS.GE,a],null),m);throw A;}};
hna=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.GE),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.ZE);c=$CLJS.J.g(c,$CLJS.SD);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.Wf.j($CLJS.Sg,$CLJS.rB,$CLJS.y($CLJS.ff($CLJS.Sa,function k(e,f){try{if($CLJS.ud(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.ce(l,$CLJS.kF)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,m,$CLJS.Xj.g(t,$CLJS.qF)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.AU(k,e,f);throw l;}throw u;}}($CLJS.xf,b)))),$CLJS.wW.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GE,$CLJS.SD],null),$CLJS.ek.g($CLJS.yf,$CLJS.Qe($CLJS.fk,b)))):a};
ina=function(a){try{return hna(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Th($CLJS.JD("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.GE,a],null),b);}throw c;}};
jna=function(a,b){var c=$CLJS.Wf.g($CLJS.fd(a),function(){return function f(e){return new $CLJS.ie(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.Yd.g(b,G);C=xW.g?xW.g(C,K):xW.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.Yd.g(b,v);return xW.g?xW.g(A,C):xW.call(null,A,C)}();if(null!=u)return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};kna=function(a,b){a=$CLJS.gk.g(function(c){var d=$CLJS.Yd.g(b,yW);return xW.g?xW.g(c,d):xW.call(null,c,d)},a);return $CLJS.n($CLJS.Me($CLJS.Sa,a))?a:null};
RV=function RV(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.yf(d);return $CLJS.ae(f,new $CLJS.ie(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),RV.g?RV.g(a,k):RV.call(null,a,k)):null},null,null))};
$CLJS.wW=function wW(a){switch(arguments.length){case 3:return wW.j(arguments[0],arguments[1],arguments[2]);case 4:return wW.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return wW.M(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return wW.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return wW.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.wW.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.wW.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.wW.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.wW.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.wW.M=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.wW.M($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.wW.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.wW.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.wW.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Ne.l($CLJS.wW,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Ne.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.wW.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.wW.A=6;
var aW=function aW(a){for(;;){if($CLJS.sd(a))return $CLJS.hs(a,aW);if($CLJS.yd(a))a=$CLJS.yf(a);else if($CLJS.ud(a)){if($CLJS.Ra($CLJS.Me($CLJS.Sa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.S:null;switch(v){case "not":if($CLJS.kl(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.S:null;switch(A){case "not":a=$CLJS.dd(m);continue;case "and":return bW($CLJS.fr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.hr,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));case "or":return bW($CLJS.lr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hr,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));default:return a}}else return a;case "and":return bW($CLJS.lr,t);case "or":return bW($CLJS.fr,t);default:return $CLJS.gk.g(aW,a)}}else return a}},lna=new $CLJS.M(null,"value_field","value_field",-980977878),zW=new $CLJS.M(null,"ascending","ascending",-988350486),
AW=new $CLJS.M(null,"named","named",-422393479),BW=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),CW=new $CLJS.M(null,"descending","descending",-24766135),DW=new $CLJS.M(null,"datetime-field","datetime-field",21731696),mna=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),EW=new $CLJS.M(null,"field-literal","field-literal",-1295883554),FW=new $CLJS.M(null,"viz-settings","viz-settings",256055379),nna=new $CLJS.M(null,"rows","rows",850049680),ona=
new $CLJS.M(null,"special-fn","special-fn",1290649344),yW=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),fW=new $CLJS.M(null,"ignore-path","ignore-path",944069061),pna=new $CLJS.M(null,"label_field","label_field",-1573182765),qna=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),rna=new $CLJS.M(null,"joined-field","joined-field",-2048778268),bna=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var GW,sna=$CLJS.Se($CLJS.N),tna=$CLJS.Se($CLJS.N),una=$CLJS.Se($CLJS.N),vna=$CLJS.Se($CLJS.N),wna=$CLJS.J.j($CLJS.N,$CLJS.Pi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));GW=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.ek.g(UV,$CLJS.z),wna,sna,tna,una,vna);GW.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E,b],null);return null!=a?$CLJS.Yd.g(b,a):b});
GW.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,b instanceof $CLJS.M?ZV(b):b],null);a=$CLJS.Be($CLJS.WV(new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.tA,null,$CLJS.qF,null,$CLJS.uQ,null],null),null),gW(a)));return $CLJS.n(a)?$CLJS.Yd.g(b,a):b});
GW.m(null,$CLJS.CU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.Yd.g(GW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CU,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CU,eW.g?eW.g(b,fW):eW.call(null,b,fW),UV(c)],null)});GW.m(null,$CLJS.kF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,b,gW(a)],null)});
GW.m(null,EW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[EW,b instanceof $CLJS.M?ZV(b):b,$CLJS.mh.h(a)],null)});GW.m(null,DW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[DW,eW.g?eW.g(b,fW):eW.call(null,b,fW),$CLJS.Yr,UV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[DW,eW.g?eW.g(b,fW):eW.call(null,b,fW),UV(c)],null)});
GW.m(null,$CLJS.dB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.Yd.g(GW.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dB,b,c,d],null)),eW.g?eW.g(a,fW):eW.call(null,a,fW)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dB,eW.g?eW.g(b,fW):eW.call(null,b,fW),$CLJS.Ad(c)?c:UV(c),UV(d)],null)});
GW.m(null,$CLJS.$A,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$A,b,UV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,$CLJS.Qu],null)});GW.m(null,$CLJS.ZA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZA,b,UV(a)],null)});
GW.m(null,$CLJS.bB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bB,eW.g?eW.g(b,fW):eW.call(null,b,fW),c,UV(a)],null)});GW.m(null,$CLJS.eB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.eB,eW.g?eW.g(b,fW):eW.call(null,b,fW),c,UV(a)],null)});
GW.m(null,$CLJS.aB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aB,eW.g?eW.g(b,fW):eW.call(null,b,fW),UV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aB,eW.g?eW.g(b,fW):eW.call(null,b,fW)],null)});
GW.m(null,$CLJS.cB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cB,eW.g?eW.g(b,fW):eW.call(null,b,fW),UV(c),UV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cB,eW.g?eW.g(b,fW):eW.call(null,b,fW),UV(c)],null)});
GW.m(null,$CLJS.YA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YA,eW.g?eW.g(b,fW):eW.call(null,b,fW),eW.g?eW.g(c,fW):eW.call(null,c,fW),UV(a)],null)});GW.m(null,$CLJS.ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,b,a],null)});
GW.m(null,$CLJS.Oh,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[UV(a)],null),$CLJS.cf.h(function(c){return eW.g?eW.g(c,fW):eW.call(null,c,fW)}),b)});
var HW=function HW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a))){var c=UV(a);var d=new $CLJS.Rg(null,new $CLJS.h(null,18,[$CLJS.ri,null,$CLJS.Lq,null,$CLJS.yF,null,$CLJS.tF,null,$CLJS.$r,null,$CLJS.lF,null,$CLJS.DF,null,$CLJS.EF,null,$CLJS.PE,null,$CLJS.WE,null,$CLJS.Kq,null,$CLJS.QE,null,$CLJS.xF,null,$CLJS.rF,null,$CLJS.Cj,null,$CLJS.Rw,null,$CLJS.eF,null,$CLJS.wF,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(cW(a))?(a=$CLJS.z(a),HW.h?HW.h(a):HW.call(null,
a)):null},xna=new $CLJS.h(null,8,[$CLJS.Si,UV,$CLJS.jP,function(a){a=VV(a);return $CLJS.y($CLJS.GP.h(a))?$CLJS.hk.j(a,$CLJS.GP,$ma):a},$CLJS.GE,new $CLJS.h(null,6,[$CLJS.$E,function IW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a)))return UV(a);if($CLJS.n(dW(AW,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[AW,IW.h?IW.h(a):IW.call(null,a)],null),c)}return $CLJS.n($CLJS.n(cW(a))?HW($CLJS.dd(a)):null)?$CLJS.gk.g(IW,a):eW.g?eW.g(a,
fW):eW.call(null,a,fW)},$CLJS.$O,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[ZV(u),eW.g?eW.g(t,fW):eW.call(null,t,fW)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[ZV(l),eW.g?eW.g(f,fW):eW.call(null,f,fW)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.OQ,function(a){return $CLJS.yf(function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m);t=$CLJS.n(cW(t))?GW.h(t):$CLJS.Zd(GW.h($CLJS.Zd(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}l=$CLJS.z(e);return $CLJS.ae($CLJS.n(cW(l))?GW.h(l):$CLJS.Zd(GW.h($CLJS.Zd(l))),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.IP,function(a){a=VV(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.jP);if($CLJS.n(b))return a=$CLJS.zU(a,new $CLJS.h(null,1,[$CLJS.jP,$CLJS.GE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jP],null),a=eW.g?eW.g(a,b):eW.call(null,a,b),$CLJS.zU(a,new $CLJS.h(null,1,[$CLJS.GE,$CLJS.jP],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.GE],null);return eW.g?eW.g(a,b):eW.call(null,a,b)},$CLJS.SN,new $CLJS.h(null,1,[yW,iW],null),$CLJS.EM,new $CLJS.h(null,1,[yW,function(a){a=eW.g?eW.g(a,$CLJS.GE):eW.call(null,a,$CLJS.GE);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.CD),d=$CLJS.J.g(b,$CLJS.SD);a=$CLJS.J.g(b,$CLJS.WD);b=$CLJS.n(c)?$CLJS.hk.j(b,$CLJS.CD,UV):b;d=$CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(d))?$CLJS.hk.j(b,$CLJS.SD,UV):b;return $CLJS.n(a)?$CLJS.hk.j(d,$CLJS.WD,ZV):d}],null)],null),$CLJS.Gx,
new $CLJS.h(null,1,[$CLJS.pN,$CLJS.Pd],null),$CLJS.vM,new $CLJS.h(null,1,[yW,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Si),d=$CLJS.J.g(b,$CLJS.mI),e=$CLJS.J.g(b,$CLJS.Hi);a=$CLJS.J.g(b,BW);b=$CLJS.n(e)?$CLJS.hk.j(b,$CLJS.Hi,ZV):b;c=$CLJS.n(c)?$CLJS.hk.j(b,$CLJS.Si,UV):b;d=$CLJS.n(d)?$CLJS.hk.j(c,$CLJS.mI,function(f){return eW.g?eW.g(f,fW):eW.call(null,f,fW)}):c;d=$CLJS.n(a)?$CLJS.wW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[BW,pna],null),function(f){return eW.g?eW.g(f,fW):eW.call(null,f,fW)}):d;return $CLJS.n(a)?
$CLJS.wW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[BW,lna],null),function(f){return eW.g?eW.g(f,fW):eW.call(null,f,fW)}):d}],null),$CLJS.qM,function(a){return null==a?null:UV(a)},$CLJS.SN,new $CLJS.h(null,1,[yW,iW],null),FW,UV],null),eW=function eW(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return eW.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
eW.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.yf(b);b=$CLJS.y(c)?$CLJS.Bz(xna,c):null;try{return $CLJS.id(b)?b.h?b.h(a):b.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=UV(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,eW.l(x,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=UV(u);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[u,eW.l(m,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),u)]))],null),k($CLJS.Hc(l)))}return null}},null,null)}(a)}()):$CLJS.n(cW(a))?GW.h(a):$CLJS.rd(a)?$CLJS.gk.g(function(e){return eW.l(e,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),yW)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.Th($CLJS.hD("Error normalizing form: {0}",$CLJS.H([$CLJS.tU(d)])),new $CLJS.h(null,3,[$CLJS.Ci,a,$CLJS.Pk,c,ona,b],null),d);}throw e;}};eW.A=1;eW.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var kW=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Pi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(cW(f))?$CLJS.z(f):null},e,a,b,c,d)}();kW.m(null,$CLJS.Oh,function(a){return a});
kW.m(null,$CLJS.kF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(dW($CLJS.kF,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return kW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,c,$CLJS.Be($CLJS.wk.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,b,$CLJS.Be(a)],null)});
kW.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.od(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,b,a],null)});kW.m(null,$CLJS.TJ,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(cW(a))?kW.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,a,null],null)});
kW.m(null,EW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,b,new $CLJS.h(null,1,[$CLJS.tA,a],null)],null)});kW.m(null,qna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=lW(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=lW(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,a,$CLJS.R.j(c,$CLJS.FM,b)],null)});
kW.m(null,rna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=lW(a);return $CLJS.Ne.v($CLJS.HU,a,$CLJS.R,$CLJS.H([$CLJS.uO,b]))});
kW.m(null,DW,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=lW(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.tA);$CLJS.Ra(d)||$CLJS.KR.g(d,b)?a=$CLJS.Ne.v($CLJS.HU,a,$CLJS.R,$CLJS.H([$CLJS.qF,b])):(c=$CLJS.Mz($CLJS.Ex),$CLJS.n($CLJS.Lz("metabase.mbql.util",c))&&(b=$CLJS.hD("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.xh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv(),b):$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),kW.h(new $CLJS.P(null,3,5,$CLJS.Q,[DW,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
kW.m(null,$CLJS.CU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=lW(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,b,$CLJS.R.j(e,$CLJS.uQ,$CLJS.wk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.CD,c],null),$CLJS.n(d)?$CLJS.Ae([c,d]):null,a])))],null)});
for(var JW=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.fr,$CLJS.hr],null)),KW=null,LW=0,MW=0;;)if(MW<LW){var NW=KW.X(null,MW);kW.m(null,NW,function(){return function(a){return mW(a)}}(JW,KW,LW,MW,NW));MW+=1}else{var OW=$CLJS.y(JW);if(OW){var PW=OW;if($CLJS.vd(PW)){var QW=$CLJS.ic(PW),yna=$CLJS.jc(PW),zna=QW,Ana=$CLJS.D(QW);JW=yna;KW=zna;LW=Ana}else{var RW=$CLJS.z(PW);kW.m(null,RW,function(){return function(a){return mW(a)}}(JW,KW,LW,MW,RW,PW,OW));JW=$CLJS.B(PW);KW=null;LW=0}MW=0}else break}
kW.m(null,$CLJS.SE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SE,lW(a),lW(b)],null),c)});kW.m(null,$CLJS.dB,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=lW(a);a=$CLJS.n($V($CLJS.kF,a))?$CLJS.HU.l(c,$CLJS.Xj,$CLJS.H([$CLJS.qF])):c;return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dB,a],null),b)});
for(var SW=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.bF,$CLJS.vF,$CLJS.jF,$CLJS.cF,$CLJS.Iq,$CLJS.AF,$CLJS.Fq,$CLJS.Hq,$CLJS.Bq,$CLJS.Dq,$CLJS.NE,$CLJS.UE,$CLJS.LE,$CLJS.XE,$CLJS.ME],null)),TW=null,UW=0,VW=0;;)if(VW<UW){var WW=TW.X(null,VW);kW.m(null,WW,function(){return function(a){return nW(a)}}(SW,TW,UW,VW,WW));VW+=1}else{var XW=$CLJS.y(SW);if(XW){var YW=XW;if($CLJS.vd(YW)){var ZW=$CLJS.ic(YW),Bna=$CLJS.jc(YW),Cna=ZW,Dna=$CLJS.D(ZW);SW=Bna;TW=Cna;UW=Dna}else{var $W=$CLJS.z(YW);kW.m(null,$W,
function(){return function(a){return nW(a)}}(SW,TW,UW,VW,$W,YW,XW));SW=$CLJS.B(YW);TW=null;UW=0}VW=0}else break}kW.m(null,nna,function(){return null});kW.m(null,$CLJS.vL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vL,kW.h(b),a],null)});
kW.m(null,AW,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=kW.h;var e=$CLJS.Q;b=kW.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,mna)?new $CLJS.h(null,1,[$CLJS.U,a],null):new $CLJS.h(null,1,[$CLJS.mD,a],null);return c.call(kW,new $CLJS.P(null,3,5,e,[$CLJS.vL,b,a],null))});
for(var aX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rw,$CLJS.lF],null)),bX=null,cX=0,dX=0;;)if(dX<cX){var eX=bX.X(null,dX);kW.m(null,eX,function(){return function(a){return oW(a)}}(aX,bX,cX,dX,eX));dX+=1}else{var fX=$CLJS.y(aX);if(fX){var gX=fX;if($CLJS.vd(gX)){var hX=$CLJS.ic(gX),Ena=$CLJS.jc(gX),Fna=hX,Gna=$CLJS.D(hX);aX=Ena;bX=Fna;cX=Gna}else{var iX=$CLJS.z(gX);kW.m(null,iX,function(){return function(a){return oW(a)}}(aX,bX,cX,dX,iX,gX,fX));aX=$CLJS.B(gX);bX=null;cX=0}dX=0}else break}
for(var jX=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.eF,$CLJS.FF,$CLJS.PE,$CLJS.yF,$CLJS.wF,$CLJS.ri,$CLJS.Cj,$CLJS.xF,$CLJS.QE],null)),kX=null,lX=0,mX=0;;)if(mX<lX){var nX=kX.X(null,mX);kW.m(null,nX,function(){return function(a){return pW(a)}}(jX,kX,lX,mX,nX));mX+=1}else{var oX=$CLJS.y(jX);if(oX){var pX=oX;if($CLJS.vd(pX)){var qX=$CLJS.ic(pX),Hna=$CLJS.jc(pX),Ina=qX,Jna=$CLJS.D(qX);jX=Hna;kX=Ina;lX=Jna}else{var rX=$CLJS.z(pX);kW.m(null,rX,function(){return function(a){return pW(a)}}(jX,kX,lX,mX,
rX,pX,oX));jX=$CLJS.B(pX);kX=null;lX=0}mX=0}else break}kW.m(null,$CLJS.WE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,lW(b),a],null)});
for(var sX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rF,$CLJS.tF],null)),tX=null,uX=0,vX=0;;)if(vX<uX){var wX=tX.X(null,vX);kW.m(null,wX,function(){return function(a){return qW(a)}}(sX,tX,uX,vX,wX));vX+=1}else{var xX=$CLJS.y(sX);if(xX){var yX=xX;if($CLJS.vd(yX)){var zX=$CLJS.ic(yX),Kna=$CLJS.jc(yX),Lna=zX,Mna=$CLJS.D(zX);sX=Kna;tX=Lna;uX=Mna}else{var AX=$CLJS.z(yX);kW.m(null,AX,function(){return function(a){return qW(a)}}(sX,tX,uX,vX,AX,yX,xX));sX=$CLJS.B(yX);tX=null;uX=0}vX=0}else break}
kW.m(null,$CLJS.DF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,kW.h(b),kW.h(a)],null)});
kW.m(null,$CLJS.TE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.Yd.g(kW.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TE,b],null)),eW.l(a,$CLJS.H([fW]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TE,$CLJS.yf(function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[kW.h(v),kW.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[kW.h(m),kW.h(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}(b)}())],null)});
kW.m(null,$CLJS.CE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,kW.h(a),$CLJS.E.g(0,b)?1:kW.h(b)],null),$CLJS.cf.g(kW,c))});
var hW=function hW(a){if($CLJS.td(a))return $CLJS.Od(function(d,e,f){return $CLJS.R.j(d,e,hW.h?hW.h(f):hW.call(null,f))},a,a);if($CLJS.sd(a))return $CLJS.hs(a,hW);if($CLJS.n(cW(a))){var c=function(){try{return kW.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.Mz($CLJS.Cx);if($CLJS.n($CLJS.Lz("metabase.mbql.normalize",e))){var f=$CLJS.JD("Invalid clause:");f instanceof Error?$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([a])),f):$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([f,
a])),null)}throw $CLJS.Th($CLJS.hD("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.tU(d)])),new $CLJS.h(null,1,[$CLJS.ZQ,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.Wf.j($CLJS.Yd.g($CLJS.fd(c),$CLJS.z(c)),$CLJS.cf.h(hW),$CLJS.Hc(c)):c}return $CLJS.yd(a)?$CLJS.gk.g(hW,a):$CLJS.pd(a)?$CLJS.Wf.j($CLJS.fd(a),$CLJS.cf.h(hW),a):a},rW=function rW(a){return function f(d,e){try{if($CLJS.n(function(){var x=XV($CLJS.yd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Z)try{if($CLJS.n(function(){var A=XV($CLJS.be);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Z;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Z)try{if($CLJS.ud(e)&&2<=$CLJS.D(e))try{var k=$CLJS.lk.j(e,0,2);if($CLJS.ud(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.fD($CLJS.be,$CLJS.Oe(new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.Lq,null,$CLJS.$r,null,$CLJS.EF,null,$CLJS.Kq,null,
AW,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(HW(m)))return $CLJS.Wf.j($CLJS.xf,$CLJS.gD(rW),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Z)try{if($CLJS.ud(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.lk.j(e,0,1);if($CLJS.ud(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;
}else throw d;else throw x;}}($CLJS.xf,a)},BX=function BX(a){return function f(d,e){try{var k=XV($CLJS.yd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.ud(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.ce(m,$CLJS.hF)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hF,t],null))}throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,
$CLJS.aF))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,t],null));throw $CLJS.Z;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,zW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hF,t],null));throw $CLJS.Z;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,CW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,t],null));throw $CLJS.Z;}catch(X){if(X instanceof Error)if(m=X,m===
$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.ce(v,zW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hF,t],null));throw $CLJS.Z;}catch(T){if(T instanceof Error)if(m=T,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,CW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,t],null));throw $CLJS.Z;}catch(da){if(da instanceof Error)if(m=da,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.hF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hF,lW(t)],null);throw $CLJS.Z;}catch(oa){if(oa instanceof
Error)if(m=oa,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.aF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aF,lW(t)],null);throw $CLJS.Z;}catch(Ma){if(Ma instanceof Error){t=Ma;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw Ma;}else throw m;else throw oa;}else throw m;else throw da;}else throw m;else throw T;}else throw m;else throw X;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{if($CLJS.ud(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.lk.j(e,0,0);if($CLJS.ud(x)&&0===$CLJS.D(x))try{var A=$CLJS.lk.g(e,0);if($CLJS.E.g(A,a))return $CLJS.yf($CLJS.xk.h($CLJS.cf.g(BX,a)));throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)return $CLJS.YV(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.xf,a)},uW=$CLJS.ek.g(hW,function(a){var b=vW($CLJS.$E.h(a))?$CLJS.hk.j(a,$CLJS.$E,cna):a;b=vW($CLJS.ZE.h(a))?$CLJS.hk.j(b,$CLJS.ZE,dna):b;b=vW($CLJS.SD.h(a))?$CLJS.hk.j(b,$CLJS.SD,$CLJS.Qe($CLJS.gk,jW)):b;b=vW($CLJS.OQ.h(a))?$CLJS.hk.j(b,$CLJS.OQ,BX):b;return vW($CLJS.IP.h(a))?$CLJS.hk.j(b,$CLJS.IP,fna):b}),Nna=new $CLJS.h(null,3,[$CLJS.jP,$CLJS.Pd,$CLJS.GE,new $CLJS.h(null,2,[$CLJS.IP,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.jP);if($CLJS.n(b))return a=
$CLJS.zU(a,new $CLJS.h(null,1,[$CLJS.jP,$CLJS.GE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jP],null),a=xW.g?xW.g(a,b):xW.call(null,a,b),$CLJS.zU(a,new $CLJS.h(null,1,[$CLJS.GE,$CLJS.jP],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.GE],null);return xW.g?xW.g(a,b):xW.call(null,a,b)},$CLJS.EM,new $CLJS.h(null,1,[yW,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.GE],null);return xW.g?xW.g(a,b):xW.call(null,a,b)}],null)],null),FW,$CLJS.Pd],null),xW=function xW(a){switch(arguments.length){case 1:return xW.h(arguments[0]);
case 2:return xW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};xW.h=function(a){return xW.g(a,$CLJS.xf)};xW.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.Bz(Nna,b):null;return $CLJS.id(c)?c.h?c.h(a):c.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?jna(a,b):$CLJS.rd(a)?kna(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Th("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Ci,a,$CLJS.Pk,b],null),d);throw d;}};xW.A=2;
$CLJS.CX=function(){var a=$CLJS.ek.l(xW,ina,gna,$CLJS.H([eW]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Th($CLJS.hD("Error normalizing query: {0}",$CLJS.H([$CLJS.tU(c)])),new $CLJS.h(null,1,[$CLJS.GE,b],null),c);}throw d;}}}();$CLJS.DX=function DX(a,b){if($CLJS.Ra($CLJS.y(a)))a=(0,$CLJS.CX)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.fs(a);b=$CLJS.Ae([$CLJS.ed(a),b]);f=DX.g?DX.g(f,b):DX.call(null,f,b);a=e.call(d,f,$CLJS.ed(a))}return a};