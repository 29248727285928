var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var tY,zoa,Aoa,Boa,Coa,AY,Doa,Eoa,BY,Foa,EY,Goa,Hoa,Ioa,JY,KY,Joa,Koa,TY,Loa,Moa,Noa,YY,Ooa;tY=function(){};$CLJS.uY=function(a){return null!=a?$CLJS.yc===a.ni?!0:a.Wc?!1:$CLJS.Ya(tY,a):$CLJS.Ya(tY,a)};zoa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.vY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Aoa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Boa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.wY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.xY=new $CLJS.M(null,"database-id","database-id",1883826326);Coa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.yY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.zY=new $CLJS.M(null,"search","search",1564939822);AY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Doa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Eoa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
BY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Foa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.CY=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.DY=new $CLJS.M(null,"dataset","dataset",1159262238);EY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);
Goa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.FY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.GY=new $CLJS.M(null,"definition","definition",-1198729982);Hoa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.HY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Ioa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.IY=new $CLJS.M("source","joins","source/joins",1225821486);
JY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);KY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.LY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.MY=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);$CLJS.NY=new $CLJS.M(null,"selected?","selected?",-742502788);
Joa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Koa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.OY=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.PY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.QY=new $CLJS.M(null,"active","active",1895962068);$CLJS.RY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.SY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);TY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Loa=new $CLJS.M(null,"details","details",1956795411);$CLJS.UY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.VY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);
Moa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.WY=new $CLJS.M("source","native","source/native",-1444604147);Noa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.XY=new $CLJS.M(null,"auto-list","auto-list",314197731);YY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);
$CLJS.ZY=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Ooa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);$CLJS.$Y=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.aZ=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.Y(JY,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.cr,$CLJS.PY,$CLJS.WY,$CLJS.wY,$CLJS.yY,$CLJS.UY,$CLJS.iK,$CLJS.$J,$CLJS.IY,$CLJS.FY,$CLJS.HY],null));$CLJS.Y(BY,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.cr],null),$CLJS.Cz($CLJS.Dd,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.XY,null,$CLJS.zY,null,$CLJS.dj,null,$CLJS.cz,null],null),null))));
$CLJS.Y(TY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.hK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hi,$CLJS.SJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.rD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TJ,$CLJS.fK],null)],null));
$CLJS.Y(AY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.bK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hi,$CLJS.SJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.rD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,$CLJS.vr],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,$CLJS.vr],null)],null)],null));
$CLJS.Y($CLJS.CY,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.us,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.JJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.wj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.AD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.fK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.er,$CLJS.wj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ni,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.AD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.fK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.fK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$Y,
new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.rD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.rD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.UJ],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.rD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.h(null,2,[$CLJS.ri,1,$CLJS.Cj,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.VY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cK,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AY],null)],null)],
null)],null));$CLJS.Y(YY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OY,$CLJS.rD],null),new $CLJS.P(null,2,5,$CLJS.Q,[Goa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[Foa,$CLJS.rD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.AD],null)],null)],null)],null)],null)],null));
$CLJS.Y(EY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QY,$CLJS.ur],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hoa,$CLJS.rD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OY,$CLJS.rD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,
$CLJS.rD],null)],null)],null));
$CLJS.Y(Moa,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.us,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.gK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hi,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.rD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xY,$CLJS.VJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.Bj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SY,
new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,$CLJS.Bj],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DY,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.OJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Boa,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,EY],null)],null)],null)],null));
$CLJS.Y(Joa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.us,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.NJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hi,$CLJS.MJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.rD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,$CLJS.OJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Bj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Is,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.rD],null)],null)],null));
$CLJS.Y(Koa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.us,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.PJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hi,$CLJS.KJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.rD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,$CLJS.OJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Bj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Is,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.rD],null)],null)],null));
$CLJS.Y(Noa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.us,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.RJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hi,$CLJS.OJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.rD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.rD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.rD],null)],null)],null));
$CLJS.Y(Aoa,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.us,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.dK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hi,$CLJS.VJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[Eoa,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Bj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Loa,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.Bj],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.aZ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.Fi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dz,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ii,$CLJS.Fi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ioa,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Bj],null)],null)],null));
$CLJS.Y(KY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"Valid MetadataProvider"],null),new $CLJS.Cc(function(){return $CLJS.uY},Coa,$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[zoa,Ooa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Gp],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.uY)?$CLJS.uY.H:null]))],null));
$CLJS.Y(Doa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.us,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KY],null)],null)],null)],null));