var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./metabase.util.malli.registry.js");
'use strict';var IU,KU,LU,xma,PU,QU,RU,zma,SU,TU,UU,WU,XU,YU,ZU,$U,aV,cV,dV,eV,fV,gV,jV,kV,yma;IU=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.fk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.he(c)}),a)};
$CLJS.JU=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Ne.g($CLJS.xB,function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.hd(l,v);$CLJS.pe(t,$CLJS.HD(x,function(){return function(A){return $CLJS.Xj.l(IU(A),$CLJS.tA,$CLJS.H([$CLJS.ni]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),e($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k);return $CLJS.ae($CLJS.HD(u,function(){return function(v){return $CLJS.Xj.l(IU(v),$CLJS.tA,$CLJS.H([$CLJS.ni]))}}(u,k,f,b)),e($CLJS.Hc(k)))}return null}},null,null)}(a)}())};
KU=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xf,a],null)],null);for(var c=$CLJS.xf;;){var d=$CLJS.md(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.nd(a);a=function(t,u,v,x,A,C,G,K){return function(S){return $CLJS.Wf.j(v,$CLJS.cf.h(function(X,T,da,oa,Ma){return function(sb){var Qa=$CLJS.I(sb,0,null);sb=$CLJS.I(sb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yd.g(Ma,Qa),sb],null)}}(t,u,v,x,A,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.Yd.g(c,l)):a=$CLJS.sd(k)?a(k):$CLJS.rd(k)?a($CLJS.Cl($CLJS.yr,k)):m}else return c}};LU=function(a,b,c){return $CLJS.ud(c)&&$CLJS.E.g(a,$CLJS.z(c))&&!$CLJS.Cd(b,$CLJS.J.g(c,2))};$CLJS.MU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.ek.g($CLJS.qD,$CLJS.dd)),$CLJS.$O.h(a));return KU($CLJS.Xj.l(a,$CLJS.EM,$CLJS.H([$CLJS.oU])),function(c){return LU($CLJS.qA,b,c)})};
$CLJS.NU=function(a){var b=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.ek.g($CLJS.yD,$CLJS.dd)),$CLJS.$E.h(a));return KU($CLJS.Xj.l(a,$CLJS.EM,$CLJS.H([$CLJS.oU])),function(c){return LU($CLJS.$E,b,c)})};xma=function(a){function b(d){return $CLJS.df(c,$CLJS.H([$CLJS.EM.h(d)]))}function c(d){return $CLJS.ae($CLJS.WD.h(d),$CLJS.df(b,$CLJS.H([$CLJS.pK.h(d)])))}return $CLJS.n($CLJS.OU.h(a))?$CLJS.Pe(!0):$CLJS.Wg(b(a))};
PU=function(a){if($CLJS.rd(a))for(var b=$CLJS.Pe(!1),c=$CLJS.y(a),d=$CLJS.z(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.z(v),A=$CLJS.B(v),C=x,G=A,K=$CLJS.zz(m,xma(C));f=$CLJS.z($CLJS.y($CLJS.ff($CLJS.Sa,function(S,X,T,da,oa,Ma){return function Ja(Qa,Xa){try{if($CLJS.ud(Xa)&&3===$CLJS.D(Xa))try{var Va=$CLJS.F(Xa,0);if($CLJS.ce(Va,$CLJS.kF))try{var Ta=$CLJS.F(Xa,1);if($CLJS.n($CLJS.uO.h(Ta)))try{var mb=$CLJS.F(Xa,1);if(null!=mb?mb.C&256||$CLJS.yc===mb.mf||(mb.C?0:$CLJS.Ya($CLJS.ub,
mb)):$CLJS.Ya($CLJS.ub,mb))try{var dc=$CLJS.J.j(mb,$CLJS.uO,$CLJS.DU);if($CLJS.n($CLJS.Oe(da)(dc))){var Kd=$CLJS.J.g(mb,$CLJS.uO);$CLJS.F(Xa,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Ma),": no join named ",$CLJS.xh.l($CLJS.H([Kd]))].join("")],null)}throw $CLJS.Z;}catch(gd){if(gd instanceof Error){var Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;
}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)throw $CLJS.Z;throw Hd;}throw gd;}else throw $CLJS.Z;}catch(gd){if(gd instanceof Error){Hd=gd;if(Hd===$CLJS.Z)return $CLJS.AU(Ja,Qa,Xa);throw Hd;}throw gd;}}}(f,k,l,K,m,t,u,v,x,A,C,G,b,0,a,a,c,d,e,d,e)($CLJS.xf,$CLJS.Xj.l(C,$CLJS.EM,$CLJS.H([yma]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};QU=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);RU=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);zma=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);SU=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
TU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);UU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.VU=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);WU=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
XU=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);YU=new $CLJS.r(null,"refs","refs",80480079,null);ZU=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);$U=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);aV=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.bV=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);cV=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);dV=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);eV=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);fV=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
gV=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.hV=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.OU=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.iV=new $CLJS.M(null,"filters","filters",974726919);jV=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);kV=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.lV=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);yma=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.Y(ZU,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.bV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jP,$CLJS.Sa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Lr,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,$CLJS.bl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.rD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GP,new $CLJS.h(null,1,[$CLJS.Br,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gU],null)],null)],null));$CLJS.Y(dV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null));
$CLJS.Y(QU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.ri,1],null),dV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"Breakouts must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.JU},RU,$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.sU,SU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[YU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.JU)?$CLJS.JU.H:null]))],null)],null));
$CLJS.Y(eV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.ri,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.oF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,":fields must be distinct"],null),new $CLJS.Cc(function(){return $CLJS.JU},RU,$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.sU,SU,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,
1,5,$CLJS.Q,[YU],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.JU)?$CLJS.JU.H:null]))],null)],null));$CLJS.Y(fV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.IE],null),$CLJS.Bj,$CLJS.wj],null)],null));
$CLJS.Y(cV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.ri,1],null),fV],null));
var mV=$CLJS.zz(function(a){a=$CLJS.z($CLJS.MU(a));if($CLJS.n(a)){var b=$CLJS.Bz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.xh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.z($CLJS.NU(a));if($CLJS.n(a)){var b=$CLJS.Bz(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.Y(gV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,2,[$CLJS.us,"Valid references for a single query stage",$CLJS.Fs,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.ej);return mV(a)}],null),$CLJS.Oe(mV)],null));
$CLJS.Y($CLJS.lV,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.VU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$O,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.ZE,new $CLJS.h(null,1,[$CLJS.Br,!0],null),QU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LF],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),eV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iV,new $CLJS.h(null,1,[$CLJS.Br,!0],null),cV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vK],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OU,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UJ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Cd(a,$CLJS.IP)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,
new $CLJS.h(null,1,[$CLJS.us,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Oe($CLJS.fD($CLJS.fO,$CLJS.OU))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gV],null)],null));$CLJS.Y(TU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OJ],null)],null)],null)],null));
$CLJS.Y(UU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UJ],null)],null)],null)],null));$CLJS.Y(XU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UU],null)],null));
$CLJS.Y($U,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Oe($CLJS.zz($CLJS.fO,$CLJS.OU))],null)],null));$CLJS.Y(aV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cr,$CLJS.bV,$CLJS.VU],null));
$CLJS.Y(zma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,aV],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.wi,$CLJS.fj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lV],null)],null)],null)],null));
$CLJS.Y(kV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,aV],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.wi,$CLJS.fj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XU],null)],null)],null)],null));$CLJS.Y(WU,$U);
$CLJS.Y(jV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,2,[$CLJS.us,"Valid references for all query stages",$CLJS.Fs,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.ej);return PU.h?PU.h(a):PU.call(null,a)}],null),$CLJS.Oe(PU)],null));
$CLJS.Y($CLJS.lK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Uq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WU],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null)],null));
$CLJS.Y($CLJS.LK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.hV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.VJ,$CLJS.aK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lK],null)],null)],null),$CLJS.pma],null));