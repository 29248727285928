var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var lha,mha,nha,oha,pha,qha,rha;$CLJS.TF=new $CLJS.M(null,"second","second",-444702010);$CLJS.UF=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);$CLJS.VF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.WF=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.XF=new $CLJS.M(null,"year-of-era","year-of-era",682445876);
lha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);mha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.YF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);
$CLJS.ZF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);nha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);oha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
pha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);qha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.$F=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);
$CLJS.aG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);rha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var bG=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.oi,$CLJS.ij,$CLJS.hj,$CLJS.ci,$CLJS.Ej,$CLJS.ji,$CLJS.Wh,$CLJS.XF],null),cG=$CLJS.Wg(bG),sha,tha,uha;$CLJS.Y(pha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid date extraction unit"],null)],null),cG));var dG=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Lk,$CLJS.Ui,$CLJS.$i,$CLJS.bi,$CLJS.Wh],null),eG=$CLJS.Wg(dG);
$CLJS.Y(oha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid date truncation unit"],null)],null),eG));$CLJS.fG=$CLJS.Wf.j($CLJS.xf,$CLJS.xk.o(),$CLJS.bf.g(dG,bG));sha=$CLJS.Wg($CLJS.fG);$CLJS.Y($CLJS.ZF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid date bucketing unit"],null)],null),sha));var gG=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UF,$CLJS.Qi,$CLJS.Bk],null),hG=$CLJS.Wg(gG);
$CLJS.Y(nha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid time extraction unit"],null)],null),hG));var iG=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.WF,$CLJS.TF,$CLJS.Fk,$CLJS.Jk],null),jG=$CLJS.Wg(iG);$CLJS.Y(rha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid time truncation unit"],null)],null),jG));$CLJS.kG=$CLJS.Wf.j($CLJS.xf,$CLJS.xk.o(),$CLJS.bf.g(iG,gG));tha=$CLJS.Wg($CLJS.kG);
$CLJS.Y(mha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid time bucketing unit"],null)],null),tha));$CLJS.lG=$CLJS.Wf.j($CLJS.xf,$CLJS.xk.o(),$CLJS.bf.l(iG,dG,$CLJS.H([gG,bG])));uha=$CLJS.Wg($CLJS.lG);$CLJS.Y($CLJS.VF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid datetime bucketing unit"],null)],null),$CLJS.lG));var vha=$CLJS.Yd.g(uha,$CLJS.Oh);
$CLJS.Y($CLJS.eD,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid temporal bucketing unit"],null)],null),vha));$CLJS.mG=$CLJS.Ws.g(eG,jG);$CLJS.Y($CLJS.YF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid datetime truncation unit"],null)],null),$CLJS.mG));$CLJS.nG=$CLJS.Ws.g(cG,hG);$CLJS.Y(lha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid datetime extraction unit"],null)],null),$CLJS.nG));
var oG=$CLJS.Yd.g(eG,$CLJS.Wh);$CLJS.Y($CLJS.aG,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid date interval unit"],null)],null),oG));$CLJS.Y($CLJS.cD,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid time interval unit"],null)],null),jG));var wha=$CLJS.Ws.g(oG,jG);$CLJS.Y($CLJS.dD,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"Valid datetime interval unit"],null)],null),wha));
$CLJS.Y(qha,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.$F],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,$CLJS.eD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null));