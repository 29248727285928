var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var CK,EK,GK,JK,MK;$CLJS.xK=function(a){return $CLJS.Hh($CLJS.q($CLJS.gB),a,$CLJS.oF)};$CLJS.yK=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.zK=new $CLJS.M("lib","source-name","lib/source-name",1931622490);$CLJS.AK=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.BK=new $CLJS.M(null,"object-id","object-id",-754527291);CK=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.DK=new $CLJS.M(null,"operators","operators",-2064102509);EK=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.FK=new $CLJS.M(null,"stage-number","stage-number",-1752729638);GK=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.HK=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.IK=new $CLJS.M(null,"dimensions","dimensions",-254818097);
JK=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.KK=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.LK=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);MK=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.NK=new $CLJS.M(null,"null","null",-180137709);
$CLJS.OK=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.Y(EK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.wD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.eD],null)],null)],null));$CLJS.Y(MK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,EK,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.AD],null)],null)],null));$CLJS.Y(GK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.kF],null),MK,$CLJS.rD],null));
$CLJS.Y(JK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.kF],null),EK,$CLJS.fK],null));
$CLJS.JF.g($CLJS.kF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.kF],null),EK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.fK,$CLJS.rD],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.wi,function(a){return $CLJS.rd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.XA(a)):null},$CLJS.us,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.lA,JK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kA,GK],null)],null)],null));$CLJS.qE($CLJS.kF,$CLJS.oF);$CLJS.LD.m(null,$CLJS.kF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});$CLJS.xE($CLJS.qA,$CLJS.H([$CLJS.rD]));$CLJS.LD.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});
$CLJS.qE($CLJS.qA,$CLJS.oF);$CLJS.Y(CK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.wD,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.rD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.rD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zK,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.rD],null)],null)],null));
$CLJS.JF.g($CLJS.$E,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.$E],null),CK,$CLJS.wj],null));$CLJS.LD.m(null,$CLJS.$E,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.zz($CLJS.ni,$CLJS.tA)(b);return $CLJS.n(a)?a:$CLJS.ZD});$CLJS.qE($CLJS.$E,$CLJS.oF);$CLJS.xE($CLJS.IE,$CLJS.H([$CLJS.$r,$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.MJ],null)],null)]));$CLJS.qE($CLJS.IE,$CLJS.oF);
$CLJS.xE($CLJS.fF,$CLJS.H([$CLJS.$r,$CLJS.ZD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KJ],null)],null)]));$CLJS.qE($CLJS.fF,$CLJS.oF);
$CLJS.Y($CLJS.oF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.ND,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.Fs,function(){return["Valid reference, must be one of these clauses: ",$CLJS.is(", ",$CLJS.Cz($CLJS.Dd,$CLJS.zk.g($CLJS.q($CLJS.gB),$CLJS.oF)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.xK(a)}],null)],null));