var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var SK,TK,UK,nja,oja,YK,pja,aL,bL,cL,dL,qja,eL,fL,hL,iL,VK,jL,rja,kL,sja,lL,mL,nL,tja,oL,pL,uja,qL,vja,rL,sL,wja,uL,xja,yja,zja,wL,xL,yL,zL,AL,BL,CL,DL,Aja,EL,FL,GL,IL,JL,Bja,KL,LL,ML,NL,Cja,OL,PL,QL,RL,Dja,SL,TL,UL,VL,Eja,WL,Fja,XL,YL,ZL,Gja,$L,aM,bM,cM,eM,fM,Hja,gM,hM,Ija,iM,jM,kM,lM,mM,Jja,nM,Kja,oM,pM,rM,Lja,sM,tM,uM,Mja,xM,yM,Nja,Oja,zM,BM,CM,DM,Pja,GM,HM,Qja,Rja,Sja,IM,JM,KM,LM,MM,NM,Tja,OM,Uja,PM,QM,SM,UM,VM,WM,XM,Vja,Wja,ZM,Xja,Yja,$M,aN,bN,cN,dN,Zja,eN,$ja,aka,fN,gN,hN,iN,jN,
bka,cka,dka,kN,eka,lN,fka,nN,oN,gka,qN,rN,sN,tN,hka,wN,ika,jka,xN,yN,zN,kka,AN,BN,CN,DN,lka,FN,GN,HN,IN,JN,mka,KN,LN,nka,MN,NN,ON,PN,QN,RN,TN,oka,UN,pka,VN,WN,XN,qka,rka,ska,ZN,$N,aO,tka,bO,cO,dO,eO,uka,vka,gO,wka,xka,hO,iO,jO,kO,lO,yka,mO,nO,oO,pO,zka,qO,rO,sO,tO,vO,Aka,wO,xO,Bka,yO,zO,AO,Cka,BO,Dka,DO,Eka,EO,FO,GO,HO,IO,JO,KO,Fka,Gka,Hka,LO,Ika,MO,NO,Jka,Kka,OO,PO,QO,Lka,RO,Mka,SO,Nka,Oka,TO,Pka,Qka,Rka,WO,XO,Ska,YO,ZO,aP,Tka,bP,Uka,cP,dP,eP,Vka,fP,gP,hP,iP,Wka,kP,lP,Xka,mP,nP,oP,pP,qP,rP,sP,tP,
Yka,uP,vP,Zka,wP,$ka,xP,yP,zP,ala,AP,BP,bla,cla,CP,DP,EP,dla,ela,FP,fla,gla,hla,HP,ila,JP,jla,kla,KP,LP,lla,mla,nla,MP,NP,OP,PP,QP,RP,SP,ola,TP,UP,pla,VP,qla,rla,sla,WP,XP,YP,tla,ZP,$P,ula,aQ,vla,bQ,wla,xla,dQ,eQ,fQ,gQ,hQ,yla,iQ,jQ,zla,kQ,lQ,mQ,nQ,oQ,pQ,qQ,rQ,Ala,sQ,tQ,vQ,wQ,xQ,yQ,zQ,AQ,BQ,CQ,DQ,EQ,Bla,FQ,GQ,HQ,IQ,JQ,Cla,KQ,LQ,MQ,NQ,Dla,PQ,Ela,Fla,RQ,Gla,SQ,Hla,TQ,Ila,Jla,UQ,Kla,VQ,XQ,YQ,Lla,Mla,bR,cR,Nla,Ola,dR,eR,fR,gR,hR,Pla,iR,jR,lR,mR,Qla;
$CLJS.RK=function(a){var b=$CLJS.Cn.g(a,null),c=$CLJS.mE(b,$CLJS.Cr,function(d){return $CLJS.Im(d,$CLJS.xf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.Qk,b,$CLJS.ej,k,$CLJS.Ir,l],null):null}function e(k){return f.j(k,$CLJS.xf,$CLJS.xf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
SK=function(a,b){return $CLJS.sd(a)?$CLJS.J.g(a,b):a};TK=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Lm($CLJS.Cn.g($CLJS.Qk.h(a),null));if($CLJS.n(b)){var e=SK($CLJS.Fs.h(b),c);$CLJS.n(e)?(e=$CLJS.Ap(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:SK($CLJS.us.h(b),c)}return null};UK=function(a,b){return $CLJS.qd(a)||$CLJS.hl(a)?$CLJS.J.g(a,b):$CLJS.rd(a)?$CLJS.J.g($CLJS.yf(a),b):null};
nja=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Qk),d=$CLJS.J.g(a,$CLJS.Si);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Ir,$CLJS.lda),f=$CLJS.J.j(b,$CLJS.gda,!0),k=$CLJS.J.g(b,$CLJS.Gs),l=$CLJS.J.j(b,$CLJS.Ds,$CLJS.Bs),m=TK(a,$CLJS.Cp.h(c),k,b);if($CLJS.n(m))return m;m=TK(a,$CLJS.Ur.h(c),k,b);if($CLJS.n(m))return m;m=TK(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=TK(a,function(){var t=$CLJS.on.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=TK(a,$CLJS.Cp.h(c),
l,b);if($CLJS.n(m))return m;m=TK(a,$CLJS.Ur.h(c),l,b);if($CLJS.n(m))return m;d=TK(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=TK(a,function(){var t=$CLJS.on.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?TK(a,e.h?e.h($CLJS.zs):e.call(null,$CLJS.zs),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?TK(a,e.h?e.h($CLJS.zs):e.call(null,$CLJS.zs),l,b):f};
oja=function(a,b,c){a=$CLJS.Q;var d=$CLJS.Wf.g;var e=$CLJS.Nk.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.Qk),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.Gs);l=$CLJS.J.j(l,$CLJS.Ds,$CLJS.Bs);k=$CLJS.Cp.h(k);f=SK($CLJS.Cs.h(k),f);f=$CLJS.n(f)?f:SK($CLJS.Cs.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.P(null,2,5,a,[d,nja(b,c)],null)};
$CLJS.WK=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.ej);a=$CLJS.J.g(b,$CLJS.Ir);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.hda,$CLJS.yi),f=$CLJS.J.j(d,$CLJS.jda,oja);return $CLJS.n(a)?$CLJS.cb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.yi,m);l=e.h?e.h(l):e.call(null,l);return VK(k,c,t,l)},null,a):null};
YK=function(a,b,c){var d=$CLJS.Bz($CLJS.q($CLJS.ZC),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.zh.v($CLJS.ZC,$CLJS.XK,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.ZK=function(a){return YK($CLJS.Mj,a,function(){return $CLJS.nE.h(a)})};pja=function(){var a=$K;return YK($CLJS.Cr,a,function(){var b=$CLJS.nE.h(a),c=$CLJS.RK(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
aL=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qk,$CLJS.he(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};bL=function(a){if($CLJS.ud(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.S:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,aL(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,aL(c)],null);default:return aL(a)}}else return aL(a)};
cL=function(a,b){var c=$CLJS.ud(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
dL=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(cL,a)],null),$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,a],null)],null)],null),function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,bL(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[m,bL(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}($CLJS.Xr(2,2,b))}())],null)};qja=function(a){return $CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
eL=function(a){return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.wi,qja,$CLJS.us,["valid instance of one of these MBQL clauses: ",$CLJS.is(", ",$CLJS.cf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.he(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.he(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};
fL=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QK],null)],null)};$CLJS.gL={};hL={};iL={};$CLJS.XK=function XK(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=XK.j?XK.j(k,f,c):XK.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
VK=function VK(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=UK(b,c);f=$CLJS.n(a)?a:$CLJS.rd(b)?$CLJS.xf:$CLJS.td(b)?$CLJS.N:$CLJS.fd(b);return $CLJS.n($CLJS.n(c)?$CLJS.As.h($CLJS.ld(f)):c)?a:$CLJS.n(c)?(b=UK(f,c),d=VK.v?VK.v(b,l,k,d):VK.call(null,b,l,k,d),$CLJS.ml(c)&&$CLJS.rd(f)&&c>$CLJS.D(f)&&(b=$CLJS.Ze(c-$CLJS.D(f),null),b=$CLJS.bf.g(f,b),f=null==f||$CLJS.yd(f)?b:$CLJS.Wf.g($CLJS.fd(f),b)),null==f||$CLJS.hl(f)?$CLJS.R.j(f,c,d):$CLJS.qd(f)?$CLJS.Yd.g(f,d):$CLJS.Ne.g($CLJS.V,$CLJS.R.j($CLJS.yf(f),
c,d))):$CLJS.sd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ida],null),VK.v?VK.v(f,b,c,d):VK.call(null,f,b,c,d)):$CLJS.n($CLJS.As.h($CLJS.ld(f)))?$CLJS.Yd.g(f,d):$CLJS.ud($CLJS.Be(f))?f:$CLJS.kd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.As,!0],null))};jL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);rja=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
kL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);sja=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);lL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);mL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);nL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
tja=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);oL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);pL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);uja=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);qL=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
vja=new $CLJS.M(null,"from","from",1815293044);rL=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);sL=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);wja=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.tL=new $CLJS.M(null,"snippet-name","snippet-name",819240328);uL=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
xja=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);yja=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.vL=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);zja=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);wL=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
xL=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);yL=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);zL=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);AL=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);BL=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
CL=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);DL=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Aja=new $CLJS.M(null,"lon-max","lon-max",1590224717);EL=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);FL=new $CLJS.r(null,"stddev","stddev",775056588,null);GL=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.HL=new $CLJS.M(null,"snippet","snippet",953581994);IL=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);JL=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Bja=new $CLJS.M(null,"lat-field","lat-field",-830652957);KL=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);LL=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
ML=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);NL=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Cja=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);OL=new $CLJS.M("location","country","location/country",1666636202);PL=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
QL=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);RL=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Dja=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);SL=new $CLJS.M(null,"unary","unary",-989314568);TL=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
UL=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);VL=new $CLJS.r(null,"ceil","ceil",-184398425,null);Eja=new $CLJS.M(null,"lon-min","lon-min",-787291357);WL=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Fja=new $CLJS.M(null,"match","match",1220059550);XL=new $CLJS.r(null,"count-where","count-where",2025939247,null);YL=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
ZL=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Gja=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);$L=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);aM=new $CLJS.r(null,"sum","sum",1777518341,null);bM=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);cM=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.dM=new $CLJS.M("date","range","date/range",1647265776);eM=new $CLJS.r(null,"between","between",-1523336493,null);fM=new $CLJS.M(null,"clause-form","clause-form",1820463737);Hja=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);gM=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);hM=new $CLJS.r(null,"field","field",338095027,null);Ija=new $CLJS.M(null,"segment-id","segment-id",1810133590);
iM=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);jM=new $CLJS.r(null,"not-null","not-null",313812992,null);kM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);lM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);mM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Jja=new $CLJS.M(null,"template-tag","template-tag",310841038);
nM=new $CLJS.M(null,"invalid","invalid",412869516);Kja=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);oM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);pM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.qM=new $CLJS.M(null,"context","context",-830191113);rM=new $CLJS.r(null,"get-year","get-year",704520253,null);
Lja=new $CLJS.M(null,"format-rows?","format-rows?",992129486);sM=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);tM=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);uM=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.vM=new $CLJS.M(null,"parameters","parameters",-1229919748);Mja=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.wM=new $CLJS.M(null,"card","card",-1430355152);xM=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);yM=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Nja=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Oja=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);zM=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.AM=new $CLJS.M("date","month-year","date/month-year",1948031290);
BM=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);CM=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);DM=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.EM=new $CLJS.M(null,"joins","joins",1033962699);Pja=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.FM=new $CLJS.M(null,"source-field","source-field",933829534);GM=new $CLJS.r(null,"Field","Field",430385967,null);
HM=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Qja=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Rja=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Sja=new $CLJS.M(null,"items","items",1031954938);IM=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);JM=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
KM=new $CLJS.M(null,"more","more",-2058821800);LM=new $CLJS.M(null,"first-clause","first-clause",-20953491);MM=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);NM=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Tja=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);OM=new $CLJS.r(null,"contains","contains",-1977535957,null);
Uja=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);PM=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);QM=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.RM=new $CLJS.M(null,"widget-type","widget-type",1836256899);SM=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.TM=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
UM=new $CLJS.r(null,"is-null","is-null",-356519403,null);VM=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);WM=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);XM=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Vja=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.YM=new $CLJS.M(null,"required","required",1807647006);Wja=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
ZM=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Xja=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Yja=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);$M=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);aN=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);bN=new $CLJS.M("string","contains","string/contains",1602423827);cN=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
dN=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);Zja=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);eN=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);$ja=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);aka=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
fN=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);gN=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);hN=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);iN=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);jN=new $CLJS.r(null,"share","share",1051097594,null);
bka=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);cka=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);dka=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);kN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);eka=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
lN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);fka=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.mN=new $CLJS.M(null,"collection","collection",-683361892);nN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);oN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.pN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);gka=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);qN=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);rN=new $CLJS.r(null,"metric","metric",2049329604,null);sN=new $CLJS.r(null,"concat","concat",-467652465,null);tN=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.uN=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
hka=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.vN=new $CLJS.M("date","relative","date/relative",25987732);wN=new $CLJS.M("location","city","location/city",-1746973325);ika=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);jka=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);xN=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);yN=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
zN=new $CLJS.M("number","between","number/between",97700581);kka=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);AN=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);BN=new $CLJS.r(null,"sqrt","sqrt",370479598,null);CN=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);DN=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.EN=new $CLJS.M(null,"semantic_type","semantic_type",272485089);lka=new $CLJS.M(null,"metric-id","metric-id",-686486942);FN=new $CLJS.r(null,"*","*",345799209,null);GN=new $CLJS.r(null,"+","+",-740910886,null);HN=new $CLJS.r(null,"-","-",-471816912,null);IN=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);JN=new $CLJS.M(null,"allowed-for","allowed-for",122724334);mka=new $CLJS.M(null,"question","question",-1411720117);KN=new $CLJS.r(null,"asc","asc",1997386096,null);
LN=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);nka=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);MN=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);NN=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);ON=new $CLJS.r(null,"\x3c","\x3c",993667236,null);PN=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);QN=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
RN=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.SN=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);TN=new $CLJS.M("string","ends-with","string/ends-with",302681156);oka=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);UN=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);pka=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
VN=new $CLJS.r(null,"and","and",668631710,null);WN=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);XN=new $CLJS.r(null,"round","round",-645002441,null);qka=new $CLJS.M(null,"to","to",192099007);$CLJS.YN=new $CLJS.M("date","single","date/single",1554682003);rka=new $CLJS.M(null,"action-id","action-id",-1727958578);ska=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);ZN=new $CLJS.r(null,"exp","exp",1378825265,null);
$N=new $CLJS.r(null,"Filter","Filter",-424893332,null);aO=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);tka=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);bO=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);cO=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);dO=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
eO=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.fO=new $CLJS.M(null,"source-table","source-table",-225307692);uka=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);vka=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);gO=new $CLJS.r(null,"floor","floor",-772394748,null);wka=new $CLJS.M(null,"middleware","middleware",1462115504);
xka=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);hO=new $CLJS.M(null,"requires-features","requires-features",-101116256);iO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);jO=new $CLJS.M(null,"clause-name","clause-name",-996419059);kO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);lO=new $CLJS.r(null,"now","now",-9994004,null);
yka=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);mO=new $CLJS.r(null,"not","not",1044554643,null);nO=new $CLJS.r(null,"avg","avg",1837937727,null);oO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);pO=new $CLJS.M(null,"max-results","max-results",-32858165);zka=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);qO=new $CLJS.r(null,"case","case",-1510733573,null);
rO=new $CLJS.r(null,"distinct","distinct",-148347594,null);sO=new $CLJS.r(null,"get-second","get-second",-425414791,null);tO=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.uO=new $CLJS.M(null,"join-alias","join-alias",1454206794);vO=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Aka=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
wO=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);xO=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Bka=new $CLJS.M(null,"original","original",-445386197);yO=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);zO=new $CLJS.r(null,"abs","abs",1394505050,null);AO=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Cka=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
BO=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Dka=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.CO=new $CLJS.M(null,"date","date",-1463434462);DO=new $CLJS.M(null,"second-clause","second-clause",-461435645);Eka=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);EO=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
FO=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);GO=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);HO=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);IO=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);JO=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);KO=new $CLJS.r(null,"or","or",1876275696,null);
Fka=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Gka=new $CLJS.M(null,"constraints","constraints",422775616);Hka=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);LO=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Ika=new $CLJS.M(null,"csv-download","csv-download",2141432084);MO=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
NO=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Jka=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Kka=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);OO=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);PO=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
QO=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Lka=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);RO=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Mka=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);SO=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Nka=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Oka=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);TO=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Pka=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Qka=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Rka=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.UO=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.VO=new $CLJS.M(null,"database","database",1849087575);WO=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);XO=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Ska=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);YO=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
ZO=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.$O=new $CLJS.M(null,"expressions","expressions",255689909);aP=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Tka=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);bP=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Uka=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);cP=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);dP=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);eP=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);Vka=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);
fP=new $CLJS.r(null,"get-day","get-day",1768100384,null);gP=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);hP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);iP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.jP=new $CLJS.M(null,"native","native",-613060878);Wka=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);kP=new $CLJS.M(null,"page","page",849072397);
lP=new $CLJS.r(null,"length","length",-2065447907,null);Xka=new $CLJS.M(null,"dashboard","dashboard",-631747508);mP=new $CLJS.r(null,"get-week","get-week",752472178,null);nP=new $CLJS.r(null,"get-month","get-month",1271156796,null);oP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);pP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);qP=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
rP=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);sP=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);tP=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Yka=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);uP=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);vP=new $CLJS.r(null,"substring","substring",-1513569493,null);
Zka=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);wP=new $CLJS.M(null,"internal","internal",-854870097);$ka=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);xP=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);yP=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
zP=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);ala=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);AP=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);BP=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);bla=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
cla=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);CP=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);DP=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);EP=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
dla=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);ela=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);FP=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.GP=new $CLJS.M(null,"template-tags","template-tags",1853115685);fla=new $CLJS.M(null,"public-question","public-question",629369976);gla=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
hla=new $CLJS.M(null,"binary","binary",-1802232288);HP=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);ila=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.IP=new $CLJS.M(null,"source-query","source-query",198004422);JP=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);jla=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
kla=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);KP=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);LP=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);lla=new $CLJS.M(null,"executed-by","executed-by",-739811161);mla=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
nla=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);MP=new $CLJS.M(null,"amount","amount",364489504);NP=new $CLJS.r(null,"percentile","percentile",1039342775,null);OP=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);PP=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);QP=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
RP=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);SP=new $CLJS.r(null,"trim","trim",-1880116002,null);ola=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);TP=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);UP=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);pla=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
VP=new $CLJS.M("string","\x3d","string/\x3d",983744235);qla=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);rla=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);sla=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);WP=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);XP=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
YP=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);tla=new $CLJS.M(null,"lat-min","lat-min",1630784161);ZP=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);$P=new $CLJS.r(null,"inside","inside",-681932758,null);ula=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);aQ=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
vla=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);bQ=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);wla=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);xla=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.cQ=new $CLJS.M(null,"card-id","card-id",-1770060179);dQ=new $CLJS.M(null,"variadic","variadic",882626057);
eQ=new $CLJS.r(null,"upper","upper",1886775433,null);fQ=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);gQ=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);hQ=new $CLJS.r(null,"optional","optional",-600484260,null);yla=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);iQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
jQ=new $CLJS.M(null,"sugar","sugar",-73788488);zla=new $CLJS.M(null,"lat-max","lat-max",841568226);kQ=new $CLJS.r(null,"power","power",702679448,null);lQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);mQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);nQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
oQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);pQ=new $CLJS.r(null,"median","median",-2084869638,null);qQ=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);rQ=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Ala=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);sQ=new $CLJS.M(null,"y","y",-1757859776);tQ=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.uQ=new $CLJS.M(null,"binning","binning",1709835866);vQ=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);wQ=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);xQ=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);yQ=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);zQ=new $CLJS.r(null,"Reference","Reference",2024574086,null);AQ=new $CLJS.M(null,"b","b",1482224470);
BQ=new $CLJS.M(null,"a","a",-2123407586);CQ=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);DQ=new $CLJS.r(null,"replace","replace",853943757,null);EQ=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Bla=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);FQ=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
GQ=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);HQ=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);IQ=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);JQ=new $CLJS.r(null,"segment","segment",675610331,null);Cla=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);KQ=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
LQ=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);MQ=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);NQ=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Dla=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.OQ=new $CLJS.M(null,"order-by","order-by",1527318070);PQ=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Ela=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Fla=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.QQ=new $CLJS.M(null,"condition","condition",1668437652);RQ=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Gla=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);SQ=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Hla=new $CLJS.M(null,"card-name","card-name",-2035606807);TQ=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Ila=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Jla=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);UQ=new $CLJS.r(null,"log","log",45015523,null);Kla=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
VQ=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.WQ=new $CLJS.M(null,"database_type","database_type",-54700895);XQ=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);YQ=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.ZQ=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.$Q=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.aR=new $CLJS.M("date","all-options","date/all-options",549325958);
Lla=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Mla=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);bR=new $CLJS.M("location","state","location/state",-114378652);cR=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Nla=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Ola=new $CLJS.M(null,"lon-field","lon-field",517872067);
dR=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);eR=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);fR=new $CLJS.M(null,"numeric","numeric",-1495594714);gR=new $CLJS.r(null,"variable","variable",1359185035,null);hR=new $CLJS.r(null,"lower","lower",-1534114948,null);Pla=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
iR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);jR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.kR=new $CLJS.M(null,"limit","limit",-1355822363);lR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);mR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Qla=new $CLJS.M(null,"pulse","pulse",-244494476);var nR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rD],null),oR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AD],null),pR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nD],null),qR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jD],null),rR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vD],null),sR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fK],null),Rla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UJ],null),Sla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OJ],null),tR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.HH],null),uR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LH],null),vR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CH],null),wR=new $CLJS.Rg(null,new $CLJS.h(null,12,[$CLJS.bi,null,$CLJS.Lk,null,$CLJS.Ui,null,$CLJS.Oh,null,$CLJS.oi,null,$CLJS.$i,null,$CLJS.Ej,null,$CLJS.ij,null,$CLJS.Wh,null,$CLJS.hj,null,$CLJS.ci,null,$CLJS.ji,null],null),null),xR=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.Jk,null,$CLJS.TF,null,$CLJS.Oh,null,$CLJS.Bk,null,$CLJS.WF,null,$CLJS.Qi,null,$CLJS.Fk,null],null),null),
yR=$CLJS.Ws.g(wR,xR),Tla=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"date bucketing unit"],null)],null),wR),Ula=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"time bucketing unit"],null)],null),xR),zR=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"datetime bucketing unit"],null)],null),yR),AR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gI],null),Vla=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,
1,[$CLJS.us,"temporal extract unit"],null),$CLJS.XF,$CLJS.ji,$CLJS.Ej,$CLJS.XH,$CLJS.kI,$CLJS.lI,$CLJS.ij,$CLJS.oi,$CLJS.Bk,$CLJS.Qi,$CLJS.UF],null),Wla=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"datetime-diff unit"],null),$CLJS.TF,$CLJS.Fk,$CLJS.Jk,$CLJS.Lk,$CLJS.Ui,$CLJS.$i,$CLJS.bi,$CLJS.Wh],null),BR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"temporal-extract week extraction mode"],null),$CLJS.RH,$CLJS.YH,$CLJS.bI],null),CR=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"relative-datetime unit"],null),$CLJS.Oh,$CLJS.Fk,$CLJS.Jk,$CLJS.Lk,$CLJS.Ui,$CLJS.$i,$CLJS.bi,$CLJS.Wh],null),JR,Yla,RR,SR,TR,UR,VR,WR,YR,KT,$la,LT,ama,bma,MT,cma,dma,ema;$CLJS.Y(UL,dL($CLJS.$A,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.Qu],null),$CLJS.qr],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,CR],null)])));var DR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UL],null);
$CLJS.Y(yO,dL($CLJS.ZA,$CLJS.H(["n",$CLJS.qr,"unit",CR])));var Xla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yO],null);
$CLJS.Y(oM,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"valid :absolute-datetime clause",$CLJS.wi,function(a){if($CLJS.Ra(cL($CLJS.VH,a)))a=nM;else{a=$CLJS.dd(a);var b=$CLJS.ZK(tR);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.CO:$CLJS.ZH}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[nM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CO,dL($CLJS.VH,
$CLJS.H(["date",tR,"unit",Tla]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZH,dL($CLJS.VH,$CLJS.H(["datetime",uR,"unit",zR]))],null)],null));var ER=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oM],null);$CLJS.Y(DM,dL($CLJS.Fx,$CLJS.H(["time",vR,"unit",Ula])));var FR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DM],null),GR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.us,"date or datetime literal"],null),ER,uR,tR],null);
$CLJS.Y(oL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.us,"time literal"],null),FR,vR],null));$CLJS.Y(dR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.us,"temporal literal"],null),GR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oL],null)],null));var HR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dR],null);
$CLJS.Y(pla,eL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof ER?new $CLJS.Cc(function(){return ER},$CLJS.kd(Qja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,wP,$CLJS.Ti,$CLJS.Oj,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eP,"metabase/mbql/schema.cljc",69,$CLJS.VH,1,!0,168,168,$CLJS.Gc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(ER)?ER.H:null])):null));return $CLJS.n(a)?a:eP}(),ER],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof DR?new $CLJS.Cc(function(){return DR},$CLJS.kd(Lla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,
$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",29,$CLJS.$A,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$A,$CLJS.Kk,$CLJS.V($CLJS.qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.Qu],null),$CLJS.qr],null)),$CLJS.di,$CLJS.V($CLJS.qj,$CLJS.V(hQ,UN))],null),$CLJS.Gc,"Schema for a valid relative-datetime clause.",
$CLJS.n(DR)?DR.H:null])):null));return $CLJS.n(a)?a:wO}(),DR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof FR?new $CLJS.Cc(function(){return FR},$CLJS.kd(Oka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,wP,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VQ,"metabase/mbql/schema.cljc",27,$CLJS.Fx,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fx,$CLJS.Fx,$CLJS.V($CLJS.qj,ola),$CLJS.di,$CLJS.V($CLJS.qj,hka)],null),$CLJS.Gc,"Schema for a valid time clause.",$CLJS.n(FR)?FR.H:null])):null));return $CLJS.n(a)?a:VQ}(),FR],null)])));
$CLJS.Y(TQ,dL($CLJS.ej,$CLJS.H(["value",$CLJS.vr,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cC,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,oR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.er,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.di,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,zR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,nR],null)],null)],null)],null)])));var IR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TQ],null);$CLJS.Y(DN,dL($CLJS.qA,$CLJS.H(["expression-name",nR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.Bj],null)])));
JR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DN],null);
Yla=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.CD);c=$CLJS.J.g(c,$CLJS.kD);return $CLJS.E.g(b,$CLJS.kD)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.CD);c=$CLJS.J.g(c,$CLJS.BD);return $CLJS.E.g(b,$CLJS.BD)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.us,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CD,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"binning strategy"],null),$CLJS.kD,$CLJS.BD,$CLJS.Oh],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),qR],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.BD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.$k,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.ul)],null)],null)],null)],null)));$CLJS.KR=function KR(a){switch(arguments.length){case 1:return KR.h(arguments[0]);case 2:return KR.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.KR.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.tA);b=$CLJS.J.g(b,$CLJS.qF);return $CLJS.KR.g(a,b)};$CLJS.KR.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.Dz(a,$CLJS.Ck)?wR:$CLJS.Dz(a,$CLJS.Mk)?xR:$CLJS.Dz(a,$CLJS.Gk)?yR:null:null;return $CLJS.n(a)?$CLJS.Cd(a,b):!0};$CLJS.KR.A=2;
$CLJS.Y(iQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.us,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,oR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,sR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,zR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,Yla],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.KR],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.CD)],null)],null));
$CLJS.Y(KQ,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.tA);return"string"===typeof c?b:!0}],null)],null)}(dL($CLJS.kF,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,sR,nR],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iQ],null)],null)]))));var LR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KQ],null);
$CLJS.Y(LO,eL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof JR?new $CLJS.Cc(function(){return JR},$CLJS.kd(PM,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.$Q,$CLJS.V($CLJS.qj,WL),$CLJS.Ni,$CLJS.V($CLJS.qj,$CLJS.V(hQ,$CLJS.Bj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(JR)?JR.H:null])):null));return $CLJS.n(a)?a:zM}(),JR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof LR?new $CLJS.Cc(function(){return LR},
$CLJS.kd(gN,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.dq,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],["0.39.0",$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hM,"metabase/mbql/schema.cljc",51,$CLJS.kF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(LR)?LR.H:null])):null));return $CLJS.n(a)?a:hM}(),LR],null)])));
$CLJS.MR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LO],null);$CLJS.Y(jL,dL($CLJS.$E,$CLJS.H(["aggregation-clause-index",$CLJS.qr,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.Bj],null)])));var NR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jL],null);
$CLJS.Y(QL,eL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof NR?new $CLJS.Cc(function(){return NR},$CLJS.kd(mla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),tQ,"metabase/mbql/schema.cljc",23,$CLJS.$E,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$E,Nla,$CLJS.V($CLJS.qj,$CLJS.qr),$CLJS.Ni,$CLJS.V($CLJS.qj,$CLJS.V(hQ,$CLJS.Bj))],null),$CLJS.Gc,"Schema for a valid aggregation clause.",$CLJS.n(NR)?NR.H:null])):null));return $CLJS.n(a)?a:tQ}(),NR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof JR?new $CLJS.Cc(function(){return JR},
$CLJS.kd(PM,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.$Q,$CLJS.V($CLJS.qj,WL),
$CLJS.Ni,$CLJS.V($CLJS.qj,$CLJS.V(hQ,$CLJS.Bj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(JR)?JR.H:null])):null));return $CLJS.n(a)?a:zM}(),JR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof LR?new $CLJS.Cc(function(){return LR},$CLJS.kd(gN,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.dq,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,
$CLJS.ei,$CLJS.Dj,$CLJS.Gj],["0.39.0",$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hM,"metabase/mbql/schema.cljc",51,$CLJS.kF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(LR)?LR.H:null])):null));return $CLJS.n(a)?a:hM}(),LR],null)])));
var OR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QL],null),PR=new $CLJS.Rg(null,new $CLJS.h(null,11,[$CLJS.ks,null,$CLJS.BF,null,$CLJS.CE,null,$CLJS.HF,null,$CLJS.JE,null,$CLJS.nF,null,$CLJS.TE,null,$CLJS.qs,null,$CLJS.zF,null,$CLJS.mF,null,$CLJS.IF,null],null),null),QR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NL],null);
$CLJS.Y(qL,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.wi,function(a){return"string"===typeof a?$CLJS.wj:$CLJS.n(cL(PR,a))?JL:$CLJS.n(cL($CLJS.ej,a))?$CLJS.ej:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,$CLJS.wj],null),new $CLJS.P(null,2,5,$CLJS.Q,[JL,QR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,IR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.MR],null)],null));RR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qL],null);
SR=new $CLJS.Rg(null,new $CLJS.h(null,26,[$CLJS.FE,null,$CLJS.jI,null,$CLJS.Lq,null,$CLJS.cB,null,$CLJS.eI,null,$CLJS.YE,null,$CLJS.$r,null,$CLJS.BE,null,$CLJS.hI,null,$CLJS.aB,null,$CLJS.nF,null,$CLJS.EF,null,$CLJS.fI,null,$CLJS.pF,null,$CLJS.HE,null,$CLJS.TH,null,$CLJS.Kq,null,$CLJS.KE,null,$CLJS.TE,null,$CLJS.NH,null,$CLJS.Pw,null,$CLJS.UH,null,$CLJS.cI,null,$CLJS.YA,null,$CLJS.GF,null,$CLJS.iF,null],null),null);
TR=new $CLJS.Rg(null,new $CLJS.h(null,9,[$CLJS.Hq,null,$CLJS.Bq,null,$CLJS.fr,null,$CLJS.hr,null,$CLJS.Dq,null,$CLJS.AF,null,$CLJS.lr,null,$CLJS.Iq,null,$CLJS.Fq,null],null),null);UR=new $CLJS.Rg(null,new $CLJS.h(null,17,[$CLJS.ri,null,$CLJS.yF,null,$CLJS.tF,null,$CLJS.lF,null,$CLJS.DF,null,$CLJS.FF,null,$CLJS.PE,null,$CLJS.WE,null,$CLJS.vL,null,$CLJS.QE,null,$CLJS.xF,null,$CLJS.rF,null,$CLJS.Cj,null,$CLJS.Rw,null,$CLJS.eF,null,$CLJS.fF,null,$CLJS.wF,null],null),null);
VR=new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.$H,null,$CLJS.PH,null,$CLJS.eB,null,$CLJS.Kq,null,$CLJS.bB,null],null),null);WR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aQ],null);YR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BM],null);$CLJS.ZR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CP],null);$CLJS.$R=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gQ],null);
$CLJS.Y(nQ,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"numeric expression argument",$CLJS.wi,function(a){return"number"===typeof a?$CLJS.Qs:$CLJS.n(cL(SR,a))?eO:$CLJS.n(cL(UR,a))?$CLJS.$E:$CLJS.n(cL($CLJS.ej,a))?$CLJS.ej:$CLJS.kF}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.$k],null),new $CLJS.P(null,2,5,$CLJS.Q,[eO,WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E,$CLJS.$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,IR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kF,
$CLJS.MR],null)],null));var aS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nQ],null);
$CLJS.Y(xP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"datetime expression argument",$CLJS.wi,function(a){return $CLJS.n(cL(UR,a))?$CLJS.$E:$CLJS.n(cL($CLJS.ej,a))?$CLJS.ej:$CLJS.n(cL(VR,a))?IM:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E,$CLJS.$R],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,IR],null),new $CLJS.P(null,2,5,$CLJS.Q,[IM,$CLJS.ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,GR,$CLJS.MR],null)],null)],null));
var bS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xP],null);
$CLJS.Y(aP,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"expression argument",$CLJS.wi,function(a){return"number"===typeof a?$CLJS.Qs:$CLJS.xd(a)?$CLJS.ur:$CLJS.n(cL(TR,a))?pP:$CLJS.n(cL(SR,a))?eO:$CLJS.n(cL(VR,a))?IM:"string"===typeof a?$CLJS.wj:$CLJS.n(cL(PR,a))?JL:$CLJS.n(cL($CLJS.ej,a))?$CLJS.ej:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.$k],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,$CLJS.ur],null),new $CLJS.P(null,2,5,$CLJS.Q,[pP,YR],null),new $CLJS.P(null,
2,5,$CLJS.Q,[eO,WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[IM,$CLJS.ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,$CLJS.wj],null),new $CLJS.P(null,2,5,$CLJS.Q,[JL,QR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,IR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.MR],null)],null));var cS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aP],null);$CLJS.Y(ZO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.us,"numeric expression arg or interval"],null),Xla,aS],null));
var dS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZO],null);$CLJS.Y(BL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"int greater than zero or numeric expression",$CLJS.wi,function(a){return"number"===typeof a?$CLJS.Qs:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,qR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,WR],null)],null));var Zla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BL],null);
$CLJS.Y(JM,dL($CLJS.nF,$CLJS.H(["a",cS,"b",cS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,cS],null)])));var eS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JM],null);$CLJS.Y(nL,dL($CLJS.CE,$CLJS.H(["s",RR,"start",Zla,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,aS],null)])));var fS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nL],null);$CLJS.Y(qP,dL($CLJS.Pw,$CLJS.H(["s",RR])));var gS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qP],null);$CLJS.Y(PL,dL($CLJS.zF,$CLJS.H(["s",RR])));
var hS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PL],null);$CLJS.Y(TL,dL($CLJS.IF,$CLJS.H(["s",RR])));var iS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TL],null);$CLJS.Y(lM,dL($CLJS.BF,$CLJS.H(["s",RR])));var jS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lM],null);$CLJS.Y(XM,dL($CLJS.ks,$CLJS.H(["s",RR])));var kS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XM],null);$CLJS.Y(GL,dL($CLJS.qs,$CLJS.H(["s",RR])));var lS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GL],null);
$CLJS.Y(mQ,dL($CLJS.HF,$CLJS.H(["s",RR,"match",$CLJS.wj,"replacement",$CLJS.wj])));var mS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null);$CLJS.Y(iR,dL($CLJS.JE,$CLJS.H(["a",RR,"b",RR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,RR],null)])));var oS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iR],null);$CLJS.Y(MN,dL($CLJS.mF,$CLJS.H(["s",RR,"pattern",$CLJS.wj])));var pS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MN],null);
$CLJS.Y(IQ,dL($CLJS.Kq,$CLJS.H(["x",dS,"y",dS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,dS],null)])));var qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null);$CLJS.Y(GQ,dL($CLJS.$r,$CLJS.H(["x",aS,"y",dS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,dS],null)])));var rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GQ],null);$CLJS.Y(FQ,dL($CLJS.EF,$CLJS.H(["x",aS,"y",aS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,aS],null)])));var sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null);
$CLJS.Y(HQ,dL($CLJS.Lq,$CLJS.H(["x",aS,"y",aS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,aS],null)])));var tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null);$CLJS.Y(YO,dL($CLJS.iF,$CLJS.H(["x",aS])));var uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YO],null);$CLJS.Y(QP,dL($CLJS.FE,$CLJS.H(["x",aS])));var vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QP],null);$CLJS.Y(lR,dL($CLJS.pF,$CLJS.H(["x",aS])));var wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lR],null);$CLJS.Y(cO,dL($CLJS.KE,$CLJS.H(["x",aS])));
var xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cO],null);$CLJS.Y(iO,dL($CLJS.HE,$CLJS.H(["x",aS,"y",aS])));var yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iO],null);$CLJS.Y(FO,dL($CLJS.YE,$CLJS.H(["x",aS])));var zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FO],null);$CLJS.Y(CL,dL($CLJS.BE,$CLJS.H(["x",aS])));var AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CL],null);$CLJS.Y(dP,dL($CLJS.GF,$CLJS.H(["x",aS])));var BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dP],null);
$CLJS.Y(bM,dL($CLJS.YA,$CLJS.H(["datetime-x",bS,"datetime-y",bS,"unit",Wla])));var CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bM],null);$CLJS.Y(tM,dL($CLJS.cB,$CLJS.H(["datetime",bS,"unit",Vla,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,BR],null)])));var DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null);$CLJS.Y(kO,dL($CLJS.UH,$CLJS.H(["date",bS])));var ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kO],null);$CLJS.Y(MO,dL($CLJS.jI,$CLJS.H(["date",bS])));
var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MO],null);$CLJS.Y(pL,dL($CLJS.NH,$CLJS.H(["date",bS])));var GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pL],null);$CLJS.Y(jR,dL($CLJS.aB,$CLJS.H(["date",bS,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,BR],null)])));var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jR],null);$CLJS.Y(xN,dL($CLJS.cI,$CLJS.H(["date",bS])));var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xN],null);$CLJS.Y(GO,dL($CLJS.fI,$CLJS.H(["date",bS])));
var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GO],null);$CLJS.Y(YL,dL($CLJS.hI,$CLJS.H(["datetime",bS])));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YL],null);$CLJS.Y(CN,dL($CLJS.eI,$CLJS.H(["datetime",bS])));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CN],null);$CLJS.Y(RL,dL($CLJS.TH,$CLJS.H(["datetime",bS])));var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RL],null);$CLJS.Y(hN,dL($CLJS.PH,$CLJS.H(["datetime",bS,"to",AR,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,AR],null)])));
var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hN],null),OS=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"datetime arithmetic unit"],null),$CLJS.WF,$CLJS.TF,$CLJS.Fk,$CLJS.Jk,$CLJS.Lk,$CLJS.Ui,$CLJS.$i,$CLJS.bi,$CLJS.Wh],null);$CLJS.Y(mL,dL($CLJS.bB,$CLJS.H(["datetime",bS,"amount",aS,"unit",OS])));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mL],null);
$CLJS.Y(KL,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return dL(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.$H));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KL],null);$CLJS.Y(lL,dL($CLJS.eB,$CLJS.H(["datetime",bS,"amount",aS,"unit",OS])));var RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lL],null);
$CLJS.Y(CP,eL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof qS?new $CLJS.Cc(function(){return qS},$CLJS.kd(SO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GN,"metabase/mbql/schema.cljc",51,$CLJS.Kq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Kq,$CLJS.zD,$CLJS.V($CLJS.qj,WO),sQ,$CLJS.V($CLJS.qj,WO),KM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,WO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(qS)?qS.H:null])):null));return $CLJS.n(a)?a:GN}(),qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof PS?new $CLJS.Cc(function(){return PS},
$CLJS.kd(yka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[aN,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NO,"metabase/mbql/schema.cljc",67,$CLJS.bB,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bB,$CLJS.ZH,$CLJS.V($CLJS.qj,eR),MP,$CLJS.V($CLJS.qj,
RP),$CLJS.di,$CLJS.V($CLJS.qj,ZP)],null),$CLJS.Gc,"Schema for a valid datetime-add clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:NO}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof RS?new $CLJS.Cc(function(){return RS},$CLJS.kd(rja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,
$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[aN,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cR,"metabase/mbql/schema.cljc",72,$CLJS.eB,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.eB,$CLJS.ZH,$CLJS.V($CLJS.qj,eR),MP,$CLJS.V($CLJS.qj,RP),$CLJS.di,$CLJS.V($CLJS.qj,ZP)],null),$CLJS.Gc,"Schema for a valid datetime-subtract clause.",$CLJS.n(RS)?RS.H:null])):null));
return $CLJS.n(a)?a:cR}(),RS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof NS?new $CLJS.Cc(function(){return NS},$CLJS.kd(vla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.PH,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HM,"metabase/mbql/schema.cljc",71,$CLJS.PH,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.PH,$CLJS.ZH,$CLJS.V($CLJS.qj,eR),qka,$CLJS.V($CLJS.qj,vQ),vja,$CLJS.V($CLJS.qj,$CLJS.V(hQ,vQ))],null),$CLJS.Gc,"Schema for a valid convert-timezone clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:HM}(),NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&
"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof QS?new $CLJS.Cc(function(){return QS},$CLJS.kd(Yka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$H,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",
45,$CLJS.$H,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$H],null),$CLJS.Gc,"Schema for a valid now clause.",$CLJS.n(QS)?QS.H:null])):null));return $CLJS.n(a)?a:lO}(),QS],null)])));$CLJS.SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IO],null);$CLJS.Y(rL,dL($CLJS.lr,$CLJS.H(["first-clause",$CLJS.SS,"second-clause",$CLJS.SS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,$CLJS.SS],null)])));var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rL],null);
$CLJS.Y(dN,dL($CLJS.fr,$CLJS.H(["first-clause",$CLJS.SS,"second-clause",$CLJS.SS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,$CLJS.SS],null)])));var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dN],null);$CLJS.Y(ML,dL($CLJS.hr,$CLJS.H(["clause",$CLJS.SS])));
var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ML],null),WS=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,3,[$CLJS.us,":field or :expression reference or :relative-datetime",$CLJS.Fs,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.wi,function(a){return $CLJS.n(cL($CLJS.$A,a))?$CLJS.$A:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,DR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.MR],null)],null);
$CLJS.Y(zP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.us,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.fr,$CLJS.ur,$CLJS.$k,$CLJS.wj,HR,WS,cS,IR],null)],null));var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zP],null);
$CLJS.Y(RN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"order comparable",$CLJS.wi,function(a){return $CLJS.n(cL($CLJS.ej,a))?$CLJS.ej:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,IR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.fr,$CLJS.$k,$CLJS.wj,HR,cS,WS],null)],null)],null));var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RN],null);
$CLJS.Y(yQ,dL($CLJS.Iq,$CLJS.H(["field",XS,"value-or-field",XS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,XS],null)])));var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yQ],null);$CLJS.Y(CQ,dL($CLJS.AF,$CLJS.H(["field",XS,"value-or-field",XS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,XS],null)])));var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);$CLJS.Y(xQ,dL($CLJS.Fq,$CLJS.H(["field",YS,"value-or-field",YS])));
var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xQ],null);$CLJS.Y(wQ,dL($CLJS.Bq,$CLJS.H(["field",YS,"value-or-field",YS])));var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wQ],null);$CLJS.Y(mM,dL($CLJS.Hq,$CLJS.H(["field",YS,"value-or-field",YS])));var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mM],null);$CLJS.Y(sM,dL($CLJS.Dq,$CLJS.H(["field",YS,"value-or-field",YS])));var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null);$CLJS.Y(hP,dL($CLJS.ME,$CLJS.H(["field",YS,"min",YS,"max",YS])));
var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hP],null);$CLJS.Y(JP,dL($CLJS.SE,$CLJS.H(["lat-field",YS,"lon-field",YS,"lat-max",YS,"lon-min",YS,"lat-min",YS,"lon-max",YS])));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JP],null);$CLJS.Y(WM,dL($CLJS.LE,$CLJS.H(["field",$CLJS.MR])));var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WM],null);$CLJS.Y(LN,dL($CLJS.XE,$CLJS.H(["field",$CLJS.MR])));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LN],null);$CLJS.Y(nN,dL($CLJS.NE,$CLJS.H(["field",$CLJS.MR])));
var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nN],null);$CLJS.Y(uL,dL($CLJS.UE,$CLJS.H(["field",$CLJS.MR])));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uL],null),kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null);$CLJS.Y(AN,dL($CLJS.bF,$CLJS.H(["field",RR,"string-or-field",RR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,kT],null)])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AN],null);
$CLJS.Y(tO,dL($CLJS.vF,$CLJS.H(["field",RR,"string-or-field",RR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,kT],null)])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tO],null);$CLJS.Y(lN,dL($CLJS.jF,$CLJS.H(["field",RR,"string-or-field",RR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,kT],null)])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lN],null);$CLJS.Y(bP,dL($CLJS.cF,$CLJS.H(["field",RR,"string-or-field",RR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,kT],null)])));
var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bP],null);$CLJS.Y(DL,dL($CLJS.dB,$CLJS.H(["field",$CLJS.MR,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.qr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cr,$CLJS.Qu,$CLJS.Vw,$CLJS.RE],null)],null),"unit",CR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ik,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null)],null)])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DL],null);
$CLJS.Y(sP,dL($CLJS.IE,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.MJ],null),nR],null)])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sP],null);
$CLJS.Y(BM,eL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof TS?new $CLJS.Cc(function(){return TS},$CLJS.kd(lQ,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VN,"metabase/mbql/schema.cljc",15,$CLJS.lr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lr,LM,$CLJS.V($CLJS.qj,$N),DO,$CLJS.V($CLJS.qj,$N),VM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,$N))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:VN}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof US?new $CLJS.Cc(function(){return US},
$CLJS.kd(HO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",14,$CLJS.fr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fr,LM,$CLJS.V($CLJS.qj,$N),DO,$CLJS.V($CLJS.qj,$N),VM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,$N))],null),$CLJS.Gc,
"Schema for a valid or clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:KO}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof VS?new $CLJS.Cc(function(){return VS},$CLJS.kd(tP,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mO,"metabase/mbql/schema.cljc",15,$CLJS.hr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.ZQ,$CLJS.V($CLJS.qj,$N)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:mO}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==
typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.kd(QO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",13,$CLJS.Fq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fq,$CLJS.kF,$CLJS.V($CLJS.qj,NM),XP,$CLJS.V($CLJS.qj,NM)],
null),$CLJS.Gc,"Schema for a valid \x3c clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:ON}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.kd(SQ,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NN,"metabase/mbql/schema.cljc",14,$CLJS.Hq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hq,$CLJS.kF,$CLJS.V($CLJS.qj,NM),XP,$CLJS.V($CLJS.qj,NM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:NN}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&
"undefined"!==typeof iL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.kd(OO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",13,$CLJS.Bq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bq,$CLJS.kF,$CLJS.V($CLJS.qj,
NM),XP,$CLJS.V($CLJS.qj,NM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:PN}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof dT?new $CLJS.Cc(function(){return dT},$CLJS.kd(LQ,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],
[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WN,"metabase/mbql/schema.cljc",14,$CLJS.Dq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dq,$CLJS.kF,$CLJS.V($CLJS.qj,NM),XP,$CLJS.V($CLJS.qj,NM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:WN}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&
"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof ZS?new $CLJS.Cc(function(){return ZS},$CLJS.kd(PO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",13,$CLJS.Iq,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.Iq,$CLJS.kF,$CLJS.V($CLJS.qj,XQ),XP,$CLJS.V($CLJS.qj,XQ),AP,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,XQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:QN}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof $S?new $CLJS.Cc(function(){return $S},$CLJS.kd(cM,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,
$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yM,"metabase/mbql/schema.cljc",14,$CLJS.AF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AF,$CLJS.kF,$CLJS.V($CLJS.qj,XQ),XP,$CLJS.V($CLJS.qj,XQ),AP,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,XQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n($S)?$S.H:null])):null));
return $CLJS.n(a)?a:yM}(),$S],null)])));
$CLJS.Y(IO,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"valid filter expression",$CLJS.wi,function(a){return $CLJS.n(cL(VR,a))?$CLJS.ZH:$CLJS.n(cL(SR,a))?fR:$CLJS.n(cL(PR,a))?$CLJS.wj:$CLJS.n(cL(TR,a))?$CLJS.ur:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZH,$CLJS.ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[fR,WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,QR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,eL($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof TS?new $CLJS.Cc(function(){return TS},$CLJS.kd(lQ,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VN,"metabase/mbql/schema.cljc",
15,$CLJS.lr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lr,LM,$CLJS.V($CLJS.qj,$N),DO,$CLJS.V($CLJS.qj,$N),VM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,$N))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:VN}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof US?new $CLJS.Cc(function(){return US},$CLJS.kd(HO,new $CLJS.h(null,1,[$CLJS.xj,
!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",14,$CLJS.fr,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fr,LM,$CLJS.V($CLJS.qj,$N),DO,$CLJS.V($CLJS.qj,$N),VM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,$N))],null),$CLJS.Gc,"Schema for a valid or clause.",$CLJS.n(US)?
US.H:null])):null));return $CLJS.n(a)?a:KO}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof VS?new $CLJS.Cc(function(){return VS},$CLJS.kd(tP,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mO,"metabase/mbql/schema.cljc",15,$CLJS.hr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hr,$CLJS.ZQ,$CLJS.V($CLJS.qj,$N)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:mO}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof ZS?new $CLJS.Cc(function(){return ZS},$CLJS.kd(PO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,
$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QN,"metabase/mbql/schema.cljc",13,$CLJS.Iq,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Iq,$CLJS.kF,$CLJS.V($CLJS.qj,XQ),XP,$CLJS.V($CLJS.qj,XQ),AP,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,XQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(ZS)?ZS.H:null])):
null));return $CLJS.n(a)?a:QN}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof $S?new $CLJS.Cc(function(){return $S},$CLJS.kd(cM,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yM,"metabase/mbql/schema.cljc",14,$CLJS.AF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AF,$CLJS.kF,$CLJS.V($CLJS.qj,XQ),XP,$CLJS.V($CLJS.qj,XQ),AP,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,XQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:yM}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},
$CLJS.kd(QO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",13,$CLJS.Fq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fq,$CLJS.kF,$CLJS.V($CLJS.qj,NM),XP,$CLJS.V($CLJS.qj,NM)],null),$CLJS.Gc,"Schema for a valid \x3c clause.",
$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:ON}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.kd(OO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PN,"metabase/mbql/schema.cljc",13,$CLJS.Bq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bq,$CLJS.kF,$CLJS.V($CLJS.qj,NM),XP,$CLJS.V($CLJS.qj,NM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:PN}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.kd(SQ,new $CLJS.h(null,
1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NN,"metabase/mbql/schema.cljc",14,$CLJS.Hq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hq,$CLJS.kF,$CLJS.V($CLJS.qj,NM),XP,$CLJS.V($CLJS.qj,NM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(cT)?cT.H:null])):
null));return $CLJS.n(a)?a:NN}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof dT?new $CLJS.Cc(function(){return dT},$CLJS.kd(LQ,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WN,"metabase/mbql/schema.cljc",14,$CLJS.Dq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dq,$CLJS.kF,$CLJS.V($CLJS.qj,NM),XP,$CLJS.V($CLJS.qj,NM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:WN}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof eT?new $CLJS.Cc(function(){return eT},$CLJS.kd(Qka,new $CLJS.h(null,
1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eM,"metabase/mbql/schema.cljc",19,$CLJS.ME,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ME,$CLJS.kF,$CLJS.V($CLJS.qj,NM),$CLJS.ri,$CLJS.V($CLJS.qj,NM),$CLJS.Cj,$CLJS.V($CLJS.qj,NM)],null),$CLJS.Gc,"Schema for a valid between clause.",
$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:eM}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof lT?new $CLJS.Cc(function(){return lT},$CLJS.kd(Ska,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bQ,"metabase/mbql/schema.cljc",23,$CLJS.bF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bF,$CLJS.kF,$CLJS.V($CLJS.qj,WP),xM,$CLJS.V($CLJS.qj,WP),$CLJS.Ni,$CLJS.V($CLJS.qj,$CLJS.V(hQ,rP))],null),$CLJS.Gc,"Schema for a valid starts-with clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:bQ}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof mT?new $CLJS.Cc(function(){return mT},
$CLJS.kd(Tja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CM,"metabase/mbql/schema.cljc",21,$CLJS.vF,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.vF,$CLJS.kF,$CLJS.V($CLJS.qj,WP),xM,$CLJS.V($CLJS.qj,WP),$CLJS.Ni,$CLJS.V($CLJS.qj,$CLJS.V(hQ,rP))],null),
$CLJS.Gc,"Schema for a valid ends-with clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:CM}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof nT?new $CLJS.Cc(function(){return nT},$CLJS.kd(fka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OM,"metabase/mbql/schema.cljc",20,$CLJS.jF,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jF,$CLJS.kF,$CLJS.V($CLJS.qj,WP),xM,$CLJS.V($CLJS.qj,WP),$CLJS.Ni,$CLJS.V($CLJS.qj,$CLJS.V(hQ,rP))],null),$CLJS.Gc,"Schema for a valid contains clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:OM}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==
typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof oT?new $CLJS.Cc(function(){return oT},$CLJS.kd(Jla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sL,"metabase/mbql/schema.cljc",36,$CLJS.cF,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.cF,$CLJS.kF,$CLJS.V($CLJS.qj,WP),xM,$CLJS.V($CLJS.qj,WP),$CLJS.Ni,$CLJS.V($CLJS.qj,$CLJS.V(hQ,rP))],null),$CLJS.Gc,"Schema for a valid does-not-contain clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:sL}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof fT?new $CLJS.Cc(function(){return fT},$CLJS.kd(sla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),
$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$P,"metabase/mbql/schema.cljc",26,$CLJS.SE,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.SE,Bja,$CLJS.V($CLJS.qj,NM),Ola,$CLJS.V($CLJS.qj,NM),zla,$CLJS.V($CLJS.qj,NM),Eja,$CLJS.V($CLJS.qj,NM),tla,$CLJS.V($CLJS.qj,NM),Aja,$CLJS.V($CLJS.qj,
NM)],null),$CLJS.Gc,"Schema for a valid inside clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:$P}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof iT?new $CLJS.Cc(function(){return iT},$CLJS.kd(gka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vO,"metabase/mbql/schema.cljc",28,$CLJS.NE,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NE,$CLJS.kF,$CLJS.V($CLJS.qj,GM)],null),$CLJS.Gc,"Schema for a valid is-empty clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:vO}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&
"undefined"!==typeof jT?new $CLJS.Cc(function(){return jT},$CLJS.kd(dla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",29,$CLJS.UE,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UE,$CLJS.kF,$CLJS.V($CLJS.qj,GM)],null),
$CLJS.Gc,"Schema for a valid not-empty clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:iP}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof gT?new $CLJS.Cc(function(){return gT},$CLJS.kd(Ela,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UM,"metabase/mbql/schema.cljc",27,$CLJS.LE,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LE,$CLJS.kF,$CLJS.V($CLJS.qj,GM)],null),$CLJS.Gc,"Schema for a valid is-null clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:UM}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&
"undefined"!==typeof hT?new $CLJS.Cc(function(){return hT},$CLJS.kd(ska,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jM,"metabase/mbql/schema.cljc",28,$CLJS.XE,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,$CLJS.kF,$CLJS.V($CLJS.qj,GM)],null),
$CLJS.Gc,"Schema for a valid not-null clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:jM}(),hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof pT?new $CLJS.Cc(function(){return pT},$CLJS.kd(sja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HP,"metabase/mbql/schema.cljc",33,$CLJS.dB,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dB,$CLJS.kF,$CLJS.V($CLJS.qj,GM),$CLJS.Kk,$CLJS.V($CLJS.qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.qr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cr,$CLJS.Qu,$CLJS.Vw,$CLJS.RE],null)],null)),$CLJS.di,$CLJS.V($CLJS.qj,UN),$CLJS.Ni,$CLJS.V($CLJS.qj,$CLJS.V(hQ,Vka))],null),$CLJS.Gc,"Schema for a valid time-interval clause.",
$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:HP}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof qT?new $CLJS.Cc(function(){return qT},$CLJS.kd(Aka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JQ,"metabase/mbql/schema.cljc",27,$CLJS.IE,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IE,Ija,$CLJS.V($CLJS.qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,jla,WL],null))],null),$CLJS.Gc,"Schema for a valid segment clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:JQ}(),qT],null)]))],null)],null));
$CLJS.Y(uP,dL($CLJS.TE,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ir,new $CLJS.h(null,1,[$CLJS.us,":case subclause"],null),$CLJS.SS,cS],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.us,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),cS],null)],null)],null)])));
var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uP],null);
$CLJS.Y(aQ,eL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof qS?new $CLJS.Cc(function(){return qS},$CLJS.kd(SO,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GN,"metabase/mbql/schema.cljc",51,$CLJS.Kq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Kq,$CLJS.zD,$CLJS.V($CLJS.qj,WO),sQ,$CLJS.V($CLJS.qj,WO),KM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,WO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(qS)?qS.H:null])):null));return $CLJS.n(a)?a:GN}(),qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof rS?new $CLJS.Cc(function(){return rS},
$CLJS.kd(Mka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HN,"metabase/mbql/schema.cljc",51,$CLJS.$r,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.$r,$CLJS.zD,$CLJS.V($CLJS.qj,RP),
sQ,$CLJS.V($CLJS.qj,WO),KM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,WO))],null),$CLJS.Gc,"Schema for a valid - clause.",$CLJS.n(rS)?rS.H:null])):null));return $CLJS.n(a)?a:HN}(),rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL?new $CLJS.Cc(function(){return sS},$CLJS.kd(Lka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,jO,$CLJS.Ai,$CLJS.Ti,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],
[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bz,"metabase/mbql/schema.cljc",$CLJS.EF,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.EF,$CLJS.zD,$CLJS.V($CLJS.qj,RP),sQ,$CLJS.V($CLJS.qj,RP),KM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,RP))],null),$CLJS.Gc,"Schema for a valid / clause.",$CLJS.n(sS)?sS.H:null])):null));return $CLJS.n(a)?
a:$CLJS.bz}(),sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof tS?new $CLJS.Cc(function(){return tS},$CLJS.kd(Nka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FN,"metabase/mbql/schema.cljc",51,$CLJS.Lq,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Lq,$CLJS.zD,$CLJS.V($CLJS.qj,RP),sQ,$CLJS.V($CLJS.qj,RP),KM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,RP))],null),$CLJS.Gc,"Schema for a valid * clause.",$CLJS.n(tS)?tS.H:null])):null));return $CLJS.n(a)?a:FN}(),tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof eS?new $CLJS.Cc(function(){return eS},
$CLJS.kd(EP,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",58,$CLJS.nF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nF,BQ,$CLJS.V($CLJS.qj,xL),AQ,$CLJS.V($CLJS.qj,
xL),KM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,xL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(eS)?eS.H:null])):null));return $CLJS.n(a)?a:TP}(),eS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof gS?new $CLJS.Cc(function(){return gS},$CLJS.kd(cla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,
$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lP,"metabase/mbql/schema.cljc",56,$CLJS.Pw,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pw,$CLJS.lx,$CLJS.V($CLJS.qj,WP)],null),$CLJS.Gc,"Schema for a valid length clause.",$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:lP}(),gS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof uS?new $CLJS.Cc(function(){return uS},$CLJS.kd(Uja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gO,"metabase/mbql/schema.cljc",55,$CLJS.iF,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iF,$CLJS.zD,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Gc,"Schema for a valid floor clause.",$CLJS.n(uS)?uS.H:null])):null));return $CLJS.n(a)?a:gO}(),uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof vS?new $CLJS.Cc(function(){return vS},$CLJS.kd(Oja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),
$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VL,"metabase/mbql/schema.cljc",54,$CLJS.FE,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FE,$CLJS.zD,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Gc,"Schema for a valid ceil clause.",$CLJS.n(vS)?
vS.H:null])):null));return $CLJS.n(a)?a:VL}(),vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof wS?new $CLJS.Cc(function(){return wS},$CLJS.kd(cka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XN,"metabase/mbql/schema.cljc",55,$CLJS.pF,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pF,$CLJS.zD,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Gc,"Schema for a valid round clause.",$CLJS.n(wS)?wS.H:null])):null));return $CLJS.n(a)?a:XN}(),wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&
"undefined"!==typeof xS?new $CLJS.Cc(function(){return xS},$CLJS.kd(Kla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zO,"metabase/mbql/schema.cljc",53,$CLJS.KE,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.KE,$CLJS.zD,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Gc,"Schema for a valid abs clause.",$CLJS.n(xS)?xS.H:null])):null));return $CLJS.n(a)?a:zO}(),xS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof yS?new $CLJS.Cc(function(){return yS},$CLJS.kd(xla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,
fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[PQ,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",69,$CLJS.HE,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.HE,$CLJS.zD,$CLJS.V($CLJS.qj,RP),sQ,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Gc,"Schema for a valid power clause.",$CLJS.n(yS)?yS.H:null])):null));return $CLJS.n(a)?a:kQ}(),yS],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof zS?new $CLJS.Cc(function(){return zS},$CLJS.kd(vka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[PQ,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BN,"metabase/mbql/schema.cljc",68,$CLJS.YE,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YE,$CLJS.zD,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Gc,"Schema for a valid sqrt clause.",$CLJS.n(zS)?zS.H:null])):null));return $CLJS.n(a)?a:BN}(),zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof AS?new $CLJS.Cc(function(){return AS},$CLJS.kd(yla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),
$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[PQ,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZN,"metabase/mbql/schema.cljc",67,$CLJS.BE,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BE,$CLJS.zD,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Gc,"Schema for a valid exp clause.",$CLJS.n(AS)?
AS.H:null])):null));return $CLJS.n(a)?a:ZN}(),AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof BS?new $CLJS.Cc(function(){return BS},$CLJS.kd(Hja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[PQ,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UQ,"metabase/mbql/schema.cljc",67,$CLJS.GF,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,$CLJS.zD,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Gc,"Schema for a valid log clause.",$CLJS.n(BS)?BS.H:null])):null));return $CLJS.n(a)?a:UQ}(),BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&
"undefined"!==typeof rT?new $CLJS.Cc(function(){return rT},$CLJS.kd(IL,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",61,$CLJS.TE,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.TE,$CLJS.bw,$CLJS.V($CLJS.qj,$M),$CLJS.Ni,$CLJS.V($CLJS.qj,$CLJS.V(hQ,JO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:qO}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof CS?new $CLJS.Cc(function(){return CS},$CLJS.kd(Kja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,
$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YA,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AO,"metabase/mbql/schema.cljc",65,$CLJS.YA,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YA,Xja,$CLJS.V($CLJS.qj,eR),Yja,$CLJS.V($CLJS.qj,eR),$CLJS.di,$CLJS.V($CLJS.qj,Dka)],null),$CLJS.Gc,"Schema for a valid datetime-diff clause.",
$CLJS.n(CS)?CS.H:null])):null));return $CLJS.n(a)?a:AO}(),CS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof DS?new $CLJS.Cc(function(){return DS},$CLJS.kd(gla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(PP,
new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TO,"metabase/mbql/schema.cljc",71,$CLJS.cB,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cB,$CLJS.ZH,$CLJS.V($CLJS.qj,eR),$CLJS.di,$CLJS.V($CLJS.qj,Dla),$CLJS.hi,$CLJS.V($CLJS.qj,$CLJS.V(hQ,mR))],null),$CLJS.Gc,"Schema for a valid temporal-extract clause.",$CLJS.n(DS)?DS.H:null])):null));return $CLJS.n(a)?a:TO}(),DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof ES?new $CLJS.Cc(function(){return ES},$CLJS.kd(oka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rM,"metabase/mbql/schema.cljc",71,$CLJS.UH,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UH,$CLJS.CO,$CLJS.V($CLJS.qj,eR)],null),$CLJS.Gc,"Schema for a valid get-year clause.",$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:rM}(),ES],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof FS?new $CLJS.Cc(function(){return FS},$CLJS.kd(dka,new $CLJS.h(null,1,[$CLJS.xj,!0],
null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",74,$CLJS.jI,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jI,$CLJS.CO,$CLJS.V($CLJS.qj,eR)],null),$CLJS.Gc,"Schema for a valid get-quarter clause.",
$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:RO}(),FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof GS?new $CLJS.Cc(function(){return GS},$CLJS.kd(Kka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",72,$CLJS.NH,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NH,$CLJS.CO,$CLJS.V($CLJS.qj,eR)],null),$CLJS.Gc,"Schema for a valid get-month clause.",$CLJS.n(GS)?GS.H:null])):null));return $CLJS.n(a)?a:nP}(),GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==
typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof HS?new $CLJS.Cc(function(){return HS},$CLJS.kd(Cla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mP,"metabase/mbql/schema.cljc",
71,$CLJS.aB,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aB,$CLJS.CO,$CLJS.V($CLJS.qj,eR),$CLJS.hi,$CLJS.V($CLJS.qj,$CLJS.V(hQ,mR))],null),$CLJS.Gc,"Schema for a valid get-week clause.",$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:mP}(),HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof IS?new $CLJS.Cc(function(){return IS},$CLJS.kd(yja,new $CLJS.h(null,1,[$CLJS.xj,
!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fP,"metabase/mbql/schema.cljc",70,$CLJS.cI,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cI,$CLJS.CO,$CLJS.V($CLJS.qj,eR)],null),$CLJS.Gc,"Schema for a valid get-day clause.",
$CLJS.n(IS)?IS.H:null])):null));return $CLJS.n(a)?a:fP}(),IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof JS?new $CLJS.Cc(function(){return JS},$CLJS.kd(Eka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",78,$CLJS.fI,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fI,$CLJS.CO,$CLJS.V($CLJS.qj,eR)],null),$CLJS.Gc,"Schema for a valid get-day-of-week clause.",$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:XO}(),JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==
typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof KS?new $CLJS.Cc(function(){return KS},$CLJS.kd(Cka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yL,"metabase/mbql/schema.cljc",
71,$CLJS.hI,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hI,$CLJS.ZH,$CLJS.V($CLJS.qj,eR)],null),$CLJS.Gc,"Schema for a valid get-hour clause.",$CLJS.n(KS)?KS.H:null])):null));return $CLJS.n(a)?a:yL}(),KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof LS?new $CLJS.Cc(function(){return LS},$CLJS.kd(kla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,
$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",73,$CLJS.eI,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eI,$CLJS.ZH,$CLJS.V($CLJS.qj,eR)],null),$CLJS.Gc,"Schema for a valid get-minute clause.",$CLJS.n(LS)?LS.H:null])):null));
return $CLJS.n(a)?a:YQ}(),LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof MS?new $CLJS.Cc(function(){return MS},$CLJS.kd(zka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,
[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",73,$CLJS.TH,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TH,$CLJS.ZH,$CLJS.V($CLJS.qj,eR)],null),$CLJS.Gc,"Schema for a valid get-second clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:sO}(),MS],null)])));
$CLJS.Y(NL,eL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof fS?new $CLJS.Cc(function(){return fS},$CLJS.kd(ala,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vP,"metabase/mbql/schema.cljc",59,$CLJS.CE,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.CE,$CLJS.lx,$CLJS.V($CLJS.qj,WP),$CLJS.vu,$CLJS.V($CLJS.qj,$ja),$CLJS.Pw,$CLJS.V($CLJS.qj,$CLJS.V(hQ,RP))],null),$CLJS.Gc,"Schema for a valid substring clause.",$CLJS.n(fS)?fS.H:null])):null));return $CLJS.n(a)?a:vP}(),fS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof hS?new $CLJS.Cc(function(){return hS},
$CLJS.kd(Ala,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",54,$CLJS.zF,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zF,$CLJS.lx,$CLJS.V($CLJS.qj,WP)],
null),$CLJS.Gc,"Schema for a valid trim clause.",$CLJS.n(hS)?hS.H:null])):null));return $CLJS.n(a)?a:SP}(),hS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof jS?new $CLJS.Cc(function(){return jS},$CLJS.kd(Fka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,
1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BP,"metabase/mbql/schema.cljc",55,$CLJS.BF,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BF,$CLJS.lx,$CLJS.V($CLJS.qj,WP)],null),$CLJS.Gc,"Schema for a valid ltrim clause.",$CLJS.n(jS)?jS.H:null])):null));return $CLJS.n(a)?a:BP}(),jS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&
"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof iS?new $CLJS.Cc(function(){return iS},$CLJS.kd(bla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EO,"metabase/mbql/schema.cljc",
55,$CLJS.IF,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,$CLJS.lx,$CLJS.V($CLJS.qj,WP)],null),$CLJS.Gc,"Schema for a valid rtrim clause.",$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:EO}(),iS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof mS?new $CLJS.Cc(function(){return mS},$CLJS.kd(tja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,
$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",57,$CLJS.HF,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HF,$CLJS.lx,$CLJS.V($CLJS.qj,WP),Fja,$CLJS.V($CLJS.qj,$CLJS.wj),$CLJS.iz,$CLJS.V($CLJS.qj,$CLJS.wj)],null),$CLJS.Gc,"Schema for a valid replace clause.",
$CLJS.n(mS)?mS.H:null])):null));return $CLJS.n(a)?a:DQ}(),mS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof lS?new $CLJS.Cc(function(){return lS},$CLJS.kd(ela,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,
new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hR,"metabase/mbql/schema.cljc",55,$CLJS.qs,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,$CLJS.lx,$CLJS.V($CLJS.qj,WP)],null),$CLJS.Gc,"Schema for a valid lower clause.",$CLJS.n(lS)?lS.H:null])):null));return $CLJS.n(a)?a:hR}(),lS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==
typeof iL&&"undefined"!==typeof kS?new $CLJS.Cc(function(){return kS},$CLJS.kd(Bla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eQ,"metabase/mbql/schema.cljc",55,$CLJS.ks,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ks,$CLJS.lx,$CLJS.V($CLJS.qj,WP)],null),$CLJS.Gc,"Schema for a valid upper clause.",$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:eQ}(),kS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof oS?new $CLJS.Cc(function(){return oS},$CLJS.kd(Uka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,
$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sN,"metabase/mbql/schema.cljc",56,$CLJS.JE,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JE,BQ,$CLJS.V($CLJS.qj,WP),AQ,$CLJS.V($CLJS.qj,WP),KM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,WP))],null),$CLJS.Gc,"Schema for a valid concat clause.",
$CLJS.n(oS)?oS.H:null])):null));return $CLJS.n(a)?a:sN}(),oS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof pS?new $CLJS.Cc(function(){return pS},$CLJS.kd($ka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.$O,"null",$CLJS.kj,"null"],
null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pM,"metabase/mbql/schema.cljc",74,$CLJS.mF,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mF,$CLJS.lx,$CLJS.V($CLJS.qj,WP),$CLJS.hz,$CLJS.V($CLJS.qj,$CLJS.wj)],null),$CLJS.Gc,"Schema for a valid regex-match-first clause.",$CLJS.n(pS)?pS.H:null])):null));return $CLJS.n(a)?a:pM}(),pS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==
typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof eS?new $CLJS.Cc(function(){return eS},$CLJS.kd(EP,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$O,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
TP,"metabase/mbql/schema.cljc",58,$CLJS.nF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nF,BQ,$CLJS.V($CLJS.qj,xL),AQ,$CLJS.V($CLJS.qj,xL),KM,$CLJS.V($CLJS.qj,$CLJS.V($CLJS.ui,xL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(eS)?eS.H:null])):null));return $CLJS.n(a)?a:TP}(),eS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof rT?new $CLJS.Cc(function(){return rT},
$CLJS.kd(IL,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",61,$CLJS.TE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.TE,$CLJS.bw,$CLJS.V($CLJS.qj,$M),
$CLJS.Ni,$CLJS.V($CLJS.qj,$CLJS.V(hQ,JO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:qO}(),rT],null)])));
$CLJS.sT=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,":field or :expression reference or expression",$CLJS.wi,function(a){return $CLJS.n(cL(SR,a))?fR:$CLJS.n(cL(PR,a))?$CLJS.wj:$CLJS.n(cL(TR,a))?$CLJS.ur:$CLJS.n(cL(VR,a))?$CLJS.ZH:$CLJS.n(cL($CLJS.TE,a))?$CLJS.TE:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[fR,WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,QR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZH,$CLJS.ZR],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TE,rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.MR],null)],null);$CLJS.Y(MQ,dL($CLJS.Rw,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.MR],null)])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MQ],null);$CLJS.Y(BO,dL($CLJS.lF,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.MR],null)])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BO],null);$CLJS.Y(LL,dL($CLJS.eF,$CLJS.H(["field-or-expression",$CLJS.sT])));
var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LL],null);$CLJS.Y(EQ,dL($CLJS.FF,$CLJS.H(["field-or-expression",$CLJS.sT])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);$CLJS.Y(kN,dL($CLJS.PE,$CLJS.H(["field-or-expression",$CLJS.sT])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null);$CLJS.Y(UP,dL($CLJS.wF,$CLJS.H(["field-or-expression",$CLJS.sT])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UP],null);$CLJS.Y(fN,dL($CLJS.ri,$CLJS.H(["field-or-expression",$CLJS.sT])));
var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fN],null);$CLJS.Y(SM,dL($CLJS.Cj,$CLJS.H(["field-or-expression",$CLJS.sT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SM],null);$CLJS.Y(MM,dL($CLJS.DF,$CLJS.H(["field-or-expression",$CLJS.sT,"pred",$CLJS.SS])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MM],null);$CLJS.Y(cN,dL($CLJS.tF,$CLJS.H(["pred",$CLJS.SS])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null);$CLJS.Y(LP,dL($CLJS.rF,$CLJS.H(["pred",$CLJS.SS])));
var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LP],null);$CLJS.Y(iM,dL($CLJS.yF,$CLJS.H(["field-or-expression",$CLJS.sT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iM],null);$CLJS.Y(kL,dL($CLJS.QE,$CLJS.H(["field-or-expression",$CLJS.sT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kL],null);$CLJS.Y(oN,dL($CLJS.xF,$CLJS.H(["field-or-expression",$CLJS.sT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oN],null);$CLJS.Y(bO,dL($CLJS.WE,$CLJS.H(["field-or-expression",$CLJS.sT,"percentile",aS])));
var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bO],null);$CLJS.Y(OP,dL($CLJS.fF,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KJ],null),nR],null)])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OP],null);
$CLJS.Y(KP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"unnamed aggregation clause or numeric expression",$CLJS.wi,function(a){return $CLJS.n(cL(SR,a))?eO:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[eO,WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,eL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof vT?new $CLJS.Cc(function(){return vT},$CLJS.kd(Zka,
new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",60,$CLJS.eF,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eF,fQ,$CLJS.V($CLJS.qj,YP)],null),$CLJS.Gc,"Schema for a valid avg clause.",
$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:nO}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof wT?new $CLJS.Cc(function(){return wT},$CLJS.kd(kka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,
new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",64,$CLJS.FF,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,fQ,$CLJS.V($CLJS.qj,YP)],null),$CLJS.Gc,"Schema for a valid cum-sum clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:rQ}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==
typeof iL&&"undefined"!==typeof xT?new $CLJS.Cc(function(){return xT},$CLJS.kd(bka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",65,$CLJS.PE,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,fQ,$CLJS.V($CLJS.qj,YP)],null),$CLJS.Gc,"Schema for a valid distinct clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:rO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof ET?new $CLJS.Cc(function(){return ET},$CLJS.kd(ula,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,
$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.uF,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FL,"metabase/mbql/schema.cljc",76,$CLJS.yF,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yF,fQ,$CLJS.V($CLJS.qj,YP)],null),$CLJS.Gc,"Schema for a valid stddev clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:FL}(),ET],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof yT?new $CLJS.Cc(function(){return yT},$CLJS.kd(Pka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aM,"metabase/mbql/schema.cljc",60,$CLJS.wF,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wF,fQ,$CLJS.V($CLJS.qj,YP)],null),$CLJS.Gc,"Schema for a valid sum clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:aM}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof zT?new $CLJS.Cc(function(){return zT},$CLJS.kd(Cja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,
$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Nr,"metabase/mbql/schema.cljc",60,$CLJS.ri,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ri,fQ,$CLJS.V($CLJS.qj,YP)],null),$CLJS.Gc,"Schema for a valid min clause.",$CLJS.n(zT)?zT.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Nr}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof AT?new $CLJS.Cc(function(){return AT},$CLJS.kd(xja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Rr,"metabase/mbql/schema.cljc",60,$CLJS.Cj,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,fQ,$CLJS.V($CLJS.qj,YP)],null),$CLJS.Gc,"Schema for a valid max clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:$CLJS.Rr}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&
"undefined"!==typeof IT?new $CLJS.Cc(function(){return IT},$CLJS.kd(Zja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rN,"metabase/mbql/schema.cljc",18,$CLJS.fF,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fF,lka,$CLJS.V($CLJS.qj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.fr,pka,WL],null))],null),$CLJS.Gc,"Schema for a valid metric clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:rN}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof DT?new $CLJS.Cc(function(){return DT},$CLJS.kd(xka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,
$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jN,"metabase/mbql/schema.cljc",62,$CLJS.rF,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,$CLJS.wn,$CLJS.V($CLJS.qj,$N)],null),$CLJS.Gc,"Schema for a valid share clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:jN}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof CT?new $CLJS.Cc(function(){return CT},$CLJS.kd(Dja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XL,"metabase/mbql/schema.cljc",68,$CLJS.tF,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tF,$CLJS.wn,$CLJS.V($CLJS.qj,$N)],null),$CLJS.Gc,"Schema for a valid count-where clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:XL}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof BT?new $CLJS.Cc(function(){return BT},$CLJS.kd(Ila,new $CLJS.h(null,1,[$CLJS.xj,!0],
null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",66,$CLJS.DF,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.DF,fQ,$CLJS.V($CLJS.qj,YP),$CLJS.wn,$CLJS.V($CLJS.qj,$N)],null),$CLJS.Gc,"Schema for a valid sum-where clause.",
$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:oO}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof rT?new $CLJS.Cc(function(){return rT},$CLJS.kd(IL,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,
new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",61,$CLJS.TE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.TE,$CLJS.bw,$CLJS.V($CLJS.qj,$M),$CLJS.Ni,$CLJS.V($CLJS.qj,$CLJS.V(hQ,JO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:qO}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&
"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof GT?new $CLJS.Cc(function(){return GT},$CLJS.kd(nla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.gF,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",
68,$CLJS.xF,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xF,fQ,$CLJS.V($CLJS.qj,YP)],null),$CLJS.Gc,"Schema for a valid median clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:pQ}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof HT?new $CLJS.Cc(function(){return HT},$CLJS.kd(Jka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,
jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.gF,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NP,"metabase/mbql/schema.cljc",72,$CLJS.WE,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.WE,fQ,$CLJS.V($CLJS.qj,YP),$CLJS.WE,$CLJS.V($CLJS.qj,RP)],null),$CLJS.Gc,"Schema for a valid percentile clause.",$CLJS.n(HT)?HT.H:null])):
null));return $CLJS.n(a)?a:NP}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof FT?new $CLJS.Cc(function(){return FT},$CLJS.kd(Nja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AL,"metabase/mbql/schema.cljc",77,$CLJS.QE,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QE,fQ,$CLJS.V($CLJS.qj,YP)],null),$CLJS.Gc,"Schema for a valid var clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:AL}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof uT?new $CLJS.Cc(function(){return uT},$CLJS.kd(zja,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,
$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aO,"metabase/mbql/schema.cljc",74,$CLJS.lF,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lF,$CLJS.kF,$CLJS.V($CLJS.qj,$CLJS.V(hQ,GM))],null),$CLJS.Gc,"Schema for a valid cum-count clause.",
$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:aO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof tT?new $CLJS.Cc(function(){return tT},$CLJS.kd(aka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([hO,$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,jQ,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.EE,"null"],null),null),
$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.gi,"metabase/mbql/schema.cljc",70,$CLJS.Rw,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.kF,$CLJS.V($CLJS.qj,$CLJS.V(hQ,GM))],null),$CLJS.Gc,"Schema for a valid count clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:$CLJS.gi}(),tT],null)]))],null)],null));
$CLJS.Y(cP,dL($CLJS.vL,$CLJS.H(["aggregation",KP,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.h(null,1,[$CLJS.us,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Br,!0],null),nR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),nR],null)],null)])));
$CLJS.Y(gQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"aggregation clause or numeric expression",$CLJS.wi,function(a){return $CLJS.n(cL($CLJS.vL,a))?$CLJS.vL:ZM}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[ZM,KP],null)],null));$CLJS.Y(EL,dL($CLJS.hF,$CLJS.H(["field",OR])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EL],null);$CLJS.Y(dO,dL($CLJS.aF,$CLJS.H(["field",OR])));
KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dO],null);
$la=eL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof JT?new $CLJS.Cc(function(){return JT},$CLJS.kd(rla,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
KN,"metabase/mbql/schema.cljc",15,$CLJS.hF,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,$CLJS.kF,$CLJS.V($CLJS.qj,zQ)],null),$CLJS.Gc,"Schema for a valid asc clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:KN}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof KT?new $CLJS.Cc(function(){return KT},$CLJS.kd(jka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,
$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FP,"metabase/mbql/schema.cljc",16,$CLJS.aF,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aF,$CLJS.kF,$CLJS.V($CLJS.qj,zQ)],null),$CLJS.Gc,"Schema for a valid desc clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:FP}(),KT],null)]));
LT=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cr,$CLJS.HL,$CLJS.wM,$CLJS.DE,$CLJS.Qs,$CLJS.Ij,$CLJS.CO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,nR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mD,nR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,new $CLJS.h(null,1,[$CLJS.Br,!0],null),nR],null)],null);
ama=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,LT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.HL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tL,nR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UO,qR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),qR],null)],null)],null);
bma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,LT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.wM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cQ,qR],null)],null)],null);
MT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,LT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null)],null);cma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qQ],null);
dma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,MT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Iq,$CLJS.DE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DE,LR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$L],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ni,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,$CLJS.Fi,$CLJS.vr],
null)],null)],null)],null)],null);$CLJS.NT=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.CO,null,$CLJS.Qs,null,$CLJS.ur,null,$CLJS.Ij,null],null),null);ema=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.cr],null),$CLJS.NT);
$CLJS.Y(yP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.wi,$CLJS.Si],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DE,dma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HL,ama],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wM,bma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xn,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,MT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,ema],null)],null)],null)],null)],null));
var OT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,nR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.U.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,nR],null)],null)],null),fma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,OT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GE,$CLJS.vr],null)],null)],null),PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gP],null),QT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.wi,function(a){return $CLJS.n($CLJS.fD($CLJS.sd,
$CLJS.jP)(a))?$CLJS.jP:$CLJS.lD}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,OT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jP,$CLJS.vr],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lD,PT],null)],null),RT=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,nR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cC,oR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Rka,nR],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.EN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.Bj],null)],null)],null),ST=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,Sla,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,new $CLJS.h(null,1,[$CLJS.us,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),gma=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.cr],null),
new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.hE,null,$CLJS.RD,null,$CLJS.aE,null,$CLJS.eE,null],null),null)),TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oQ],null);
$CLJS.Y(iN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),ST],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QQ,$CLJS.SS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),gma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.us,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cr,$CLJS.wx,$CLJS.cz],null),TT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),nR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,sR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SN,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,RT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.fD($CLJS.zz($CLJS.fO,$CLJS.IP),$CLJS.Oe($CLJS.fD($CLJS.fO,$CLJS.IP)))],null)],null));var hma=$CLJS.Q,UT;var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iN],null)],null);
if($CLJS.rd(VT)&&$CLJS.E.g($CLJS.z(VT),$CLJS.bj)){var WT=$CLJS.y(VT);$CLJS.z(WT);var XT=$CLJS.B(WT),ima=$CLJS.sd($CLJS.z(XT))?XT:$CLJS.ae(null,XT),YT=$CLJS.y(ima),jma=$CLJS.z(YT),kma=$CLJS.B(YT);UT=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,$CLJS.R.j(jma,$CLJS.ri,1)],null),kma)}else UT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,VT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.Y(kM,new $CLJS.P(null,3,5,hma,[$CLJS.lr,UT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"All join aliases must be unique."],null),function(a){return $CLJS.PK($CLJS.ff($CLJS.Sa,$CLJS.cf.g($CLJS.WD,a)))}],null)],null));var lma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kM],null);
$CLJS.Y(oQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qk,new $CLJS.h(null,1,[$CLJS.us,"Distinct, non-empty sequence of Field clauses"],null),fL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.ri,1],null),$CLJS.MR],null))],null));
$CLJS.Y(gP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),QT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),ST],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.ri,1],null),$CLJS.$R],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,new $CLJS.h(null,1,
[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.ri,1],null),$CLJS.MR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$O,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,nR,$CLJS.sT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),TT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OE,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.SS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kR,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),rR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),fL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bj,new $CLJS.h(null,1,[$CLJS.ri,1],null),$la],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[kP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[kP,qR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sja,qR],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),lma],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,RT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.Dl(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IP,$CLJS.fO],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,
new $CLJS.h(null,1,[$CLJS.us,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.ZE);b=$CLJS.J.g(b,$CLJS.SD);return $CLJS.od($CLJS.ct.g($CLJS.Wg(a),$CLJS.Wg(b)))}],null)],null));
$CLJS.ZT=$CLJS.zg([$CLJS.CF,$CLJS.CO,eN,QM,TN,bR,zN,$CLJS.aR,$CLJS.Qs,yN,OL,VP,RQ,$CLJS.dM,NQ,bN,$CLJS.YN,wN,$CLJS.Hi,$CLJS.vN,wL,$CLJS.AM,$CLJS.uN,xO,$CLJS.ur,$CLJS.Ij,qN],[new $CLJS.h(null,1,[JN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.CF,null,$CLJS.CO,null,$CLJS.Qs,null,$CLJS.ur,null,$CLJS.Ij,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.CO,JN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.CF,null,$CLJS.CO,null,$CLJS.aR,null,$CLJS.YN,null,$CLJS.Hi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Si,$CLJS.wj,$CLJS.uD,dQ,JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[eN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,fR,$CLJS.uD,dQ,JN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.CF,null,QM,null,$CLJS.Qs,null,$CLJS.Hi,null,wL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,$CLJS.wj,$CLJS.uD,SL,JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[TN,null],null),null)],null),new $CLJS.h(null,1,[JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[bR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,
fR,$CLJS.uD,hla,JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[zN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.CO,JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.aR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,fR,JN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.CF,null,QM,null,$CLJS.Qs,null,$CLJS.Hi,null,wL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,fR,$CLJS.uD,SL,JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[yN,null],null),null)],null),new $CLJS.h(null,1,[JN,new $CLJS.Rg(null,
new $CLJS.h(null,1,[OL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,$CLJS.wj,$CLJS.uD,dQ,JN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.CF,null,bR,null,OL,null,VP,null,wN,null,$CLJS.Hi,null,wL,null,$CLJS.Ij,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,$CLJS.wj,$CLJS.uD,SL,JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[RQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.CO,JN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.aR,null,$CLJS.dM,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Si,$CLJS.wj,$CLJS.uD,SL,JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[NQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,$CLJS.wj,$CLJS.uD,SL,JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[bN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.CO,JN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.CF,null,$CLJS.CO,null,$CLJS.aR,null,$CLJS.YN,null,$CLJS.Hi,null],null),null)],null),new $CLJS.h(null,1,[JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[wN,null],null),null)],null),new $CLJS.h(null,1,[JN,
new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.Hi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.CO,JN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.aR,null,$CLJS.vN,null],null),null)],null),new $CLJS.h(null,1,[JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[wL,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.CO,JN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.aR,null,$CLJS.AM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.CO,JN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.aR,
null,$CLJS.uN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,fR,$CLJS.uD,dQ,JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[xO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.ur,JN,new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.CF,null,$CLJS.Hi,null,$CLJS.ur,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Si,$CLJS.wj,JN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.CF,null,bR,null,OL,null,VP,null,wN,null,$CLJS.Hi,null,wL,null,$CLJS.Ij,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Si,
fR,$CLJS.uD,SL,JN,new $CLJS.Rg(null,new $CLJS.h(null,1,[qN,null],null),null)],null)]);$CLJS.Y(qQ,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"valid parameter type"],null)],null),$CLJS.Ng($CLJS.ZT)));$CLJS.Y($L,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cr,new $CLJS.h(null,1,[$CLJS.us,"valid template tag widget type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.ZT)));
$CLJS.Y(DP,dL(Jja,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,nR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hi,nR],null)],null)],null)])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DP],null);$CLJS.Y(zL,dL($CLJS.DE,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.MR,$T],null)])));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zL],null);$CLJS.Y(uM,dL(tN,$CLJS.H(["target",$T])));
var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null),mma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,$CLJS.MR,eL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof aU?new $CLJS.Cc(function(){return aU},$CLJS.kd(Hka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,
1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",21,$CLJS.DE,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DE,$CLJS.mI,$CLJS.V($CLJS.qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,GM,IN],null))],null),$CLJS.Gc,"Schema for a valid dimension clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:oP}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jO.h($CLJS.ld("undefined"!==typeof $CLJS.gL&&
"undefined"!==typeof hL&&"undefined"!==typeof iL&&"undefined"!==typeof bU?new $CLJS.Cc(function(){return bU},$CLJS.kd(Wka,new $CLJS.h(null,1,[$CLJS.xj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Hj,$CLJS.fi,jO,$CLJS.Ai,$CLJS.Ti,$CLJS.Oj,fM,$CLJS.ei,$CLJS.Dj,$CLJS.Gj],[$CLJS.kd(PP,new $CLJS.h(null,1,[$CLJS.Dj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gR,"metabase/mbql/schema.cljc",20,tN,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[tN,
$CLJS.mI,$CLJS.V($CLJS.qj,IN)],null),$CLJS.Gc,"Schema for a valid variable clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:gR}(),bU],null)]))],null),$K=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gM],null);
$CLJS.Y(gM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.fD($CLJS.zz($CLJS.jP,$CLJS.GE),$CLJS.Oe($CLJS.fD($CLJS.jP,$CLJS.GE)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.jP);var d=$CLJS.J.g(c,$CLJS.GE);c=$CLJS.J.g(c,$CLJS.Si);c=c instanceof $CLJS.M?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.us,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aK],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.VJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cr,$CLJS.GE,$CLJS.jP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),fma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GE,new $CLJS.h(null,1,[$CLJS.Br,!0],null),PT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,new $CLJS.P(null,
9,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,cma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,new $CLJS.h(null,1,[$CLJS.Br,!0],null),nR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mI,new $CLJS.h(null,1,[$CLJS.Br,!0],null),mma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Br,!0],null),nR],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),
nR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[Gla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),AR],null)],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[Gka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[pO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),rR],null),new $CLJS.P(null,3,5,$CLJS.Q,[ZL,new $CLJS.h(null,1,[$CLJS.Br,!0],null),rR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.O(a);a=$CLJS.J.g(b,pO);b=$CLJS.J.g(b,ZL);return $CLJS.Ra($CLJS.n(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[qla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.ur],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[uja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[ila,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.ur],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gx,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.cr,$CLJS.gj,Tka,$CLJS.mN,Vja,Qla,Xka,mka,Ika,eka,Mja,ika,fla,uka,Gja,Wja,Pla,Mla],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[lla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[rka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,Rla],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,nR],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[tka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,$CLJS.vr,$CLJS.vr],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[nka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pN,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Di,$CLJS.vr,$CLJS.vr],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.er,$CLJS.vr],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.us,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],
null),$CLJS.Oe($CLJS.SN)],null)],null));var cU=$CLJS.ZK($K);(function(){var a=pja();return function(b){if($CLJS.n(cU.h?cU.h(b):cU.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.WK(b);throw $CLJS.Uh($CLJS.hD("Invalid query: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Cx,c,Bka,b],null));}})();